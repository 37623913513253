.choosen-measurement-type-card-circles-wrapper
    display: flex
    flex-direction: row
    align-items: center
    margin: 0 auto
   
    div
        margin: 0 9px
        border-radius: 50%
        @media (max-width: 640px)
            margin: 0 4px
    .white-circle
        width: 20px
        height: 20px
        background: #FFFFFF
        cursor: pointer
        @media (max-width: 640px)
            width: 14px
            height: 14px
    .green-circle
        width: 30px
        height: 30px
        cursor: pointer
        background: #219653
        @media (max-width: 640px)
            width: 16px
            height: 16px