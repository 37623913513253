.articles-navigataion-wrapper
    max-width: 331px
    width: 100%
    display: flex
    flex-direction: column
    @media ( max-width: 640px )
        height: 80vh
        overflow-y: scroll
    &__section
        width: 100%
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        background: #FDFDFD
        border-radius: 4px
        margin: 4px 0
        box-sizing: border-box
        padding: 14px 14px 14px 16px
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: normal
        font-size: 16px
        line-height: 24px
        color: #211E1E
        cursor: pointer
        span
            max-width: 267px
            @media ( max-width: 640px )
                max-width: 199px
        svg
            transition: all .6s ease-in-out

    .active-section
        background: #219653
        color: #FFFFFF

        svg 
            transform: rotate(180deg)
            path
                fill: #219653
                stroke: white
    
    &__articles-list-wrapper
        max-height: 0
        overflow-y: scroll
        transition: max-height 0.45s ease-out
        display: flex
        flex-direction: column
        -webkit-scrollbar
            display: none
            width: 0
        & > div
            position: relative
            box-sizing: border-box
            padding: 0 16px
            margin: 8px 0
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: normal
            font-size: 16px
            line-height: 24px
            color: #211E1E
            cursor: pointer
            span
                max-width: 299px
                @media ( max-width: 640px )
                    width: 199px
                    white-space: pre-line
            .choosen-article-block
                position: absolute
                left: 0
                top: 0
                height: 100%
                width: 6px
                background: #219653
                border-radius: 0px 3px 3px 0px
                
        .choosen-article
            color: #219653
            font-weight: 600

    .opened-article-list
        max-height: 500px
        transition: max-height 0.85s ease-in
        color: #219653
        margin: 16px 0px 24px 0px
::-webkit-scrollbar 
    width: 0px
    background: transparent
