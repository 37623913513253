
  .confirm-register
    div
      font-size: 48px
      display: flex
      align-items: center
      justify-content: space-between
      a
        width: fit-content
  .confirm__message
    position: fixed
    top: 20%
    left: 50%
    transform: translate(-50%,-50%)
  .header_comp
    padding: 20px
    background: #fff
  .header_comp
    img
      width: 80px


  