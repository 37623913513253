.bottom-control-panel-wrapper
    position: absolute
    margin-top: auto
    bottom: 0
    right: 0
    width: 100%
    height: fit-content
    padding: 20px 30px
    box-sizing: border-box
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-end
    background: #FFFFFF
    z-index: 100
    @media (max-width: 640px)
        justify-content: space-between
        padding: 7px 18px 11px 18px
        position: fixed
    .white-button
        margin-right: 16px
        @media (max-width: 640px)
            margin: 0
    .green-button
       
        min-width: 120px
        width: fit-content