@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800&display=swap')
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@200;300;400;500;600;700&display=swap')

html 
  box-sizing: border-box
  height: -webkit-fill-available

body 
  height: 100%
  min-height: -webkit-fill-available
  width: 100vw
  margin: 0
  padding: 0
  overflow: hidden
  background-color: #FCFCFD
  
  font-family: 'Inter', sans-serif
  font-size: 14px
  
// ::-webkit-scrollbar 
//   width: 6px
//   height: 6px
// ::-webkit-scrollbar-track 
//   box-shadow: 0
//   border-radius: 5px
// ::-webkit-scrollbar-thumb 
//   background: #219653
//   border-radius: 5px
//   width: 6px
//   height: 6px
.container 
    width: 100vw
    height: calc(100vh - 72px)
    background: #F4F4F4
    margin: 0
    padding: 0
    overflow-x: hidden
    @media (max-width: 640px)
      height: 100vh

a
  text-decoration: none

// global styles for buttons

.green-button
  width: 100%
  max-width: 332px
  text-align: center
  padding: 10px 16px
  border: 2px solid #219653
  box-sizing: border-box
  border-radius: 12px
  text-decoration: none
  background: #219653
  color: white
  cursor: pointer

  font-family: Open Sans, sans-serif
  font-style: normal
  font-weight: 600
  font-size: 16px
  line-height: 20px
  color: #FFFFFF

  transition: all .2s ease
  &:hover
    background: #184F2F
    border: 2px solid #184F2F

  &:active
    background: #FFFFFF
    border: 2px solid #219653
    color: #219653
  a
    color: #FFFFFF
  @media screen and (max-width: 332px)
    max-width: 304px

.white-button
  display: flex
  flex-direction: row
  align-items: center
  max-width: 332px
  padding: 10px 16px
  text-align: center
  border: 2px solid #219653
  box-sizing: border-box
  border-radius: 12px
  text-decoration: none
  color: #219653
  background: #FFFFFF
  cursor: pointer

  font-family: Open Sans, sans-serif
  font-style: normal
  font-weight: 600
  font-size: 16px
  line-height: 20px

  transition: all .2s ease
  &:hover
    border: 2px solid #184F2F
    color: #184F2F
  &:active
    background: #219653
    border: 2px solid #219653
    color: white
  @media screen and (max-width: 332px)
      max-width: 304px

.disabled-button
  opacity: 0.4
  pointer-events: none

// global text input styles
div.text-input input
    max-width: 332px
    font-family: Montserrat, sans-serif
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 20px
    color: #211E1E
    outline: none
    border: none
    width: 100%
    padding: 10px 16px
    background: #FFFFFF
    border-radius: 12px
    box-sizing: border-box
    @media (max-width: 640px)
      font-size: 14px
      line-height: 17px
    @media screen and (max-width: 332px)
      max-width: 304px
    &::placeholder
      font-family: Montserrat, sans-serif
      font-style: normal
      font-weight: normal
      font-size: 16px
      line-height: 20px
      color: rgba(33, 30, 30, 0.5)
    &::focus
      outline: none
      border-color: inherit
      -webkit-box-shadow: none
      box-shadow: none

div.text-input
  .error-input
    border: 1px solid #E3655D

.error-text
  font-family: Montserrat, sans-serif
  font-style: normal
  font-weight: normal
  font-size: 13px
  line-height: 24px
  color: #E3655D
  
.social-networks-login-wrapper
  display: flex
  flex-direction: column
  margin-top: 15px
  width: 100%
  @media (max-width: 640px)
    align-items: center
  span
    font-family: Open Sans, sans-serif
    font-style: normal
    font-weight: 600
    font-size: 14px
    line-height: 24px
    color: #9292A3
    margin-bottom: 16px
  div
    display: flex
    flex-direction: row
    svg
      margin-right: 16px
      cursor: pointer



// states of pages with opened and closed left navbar
.page-with-closed-navbar
  max-width: 100%
  width: 100%
  transition: max-width 0.6s ease

.page-with-opened-navbar
  width: 100%
  max-width: calc(100% - 260px)
  transition: max-width 0.6s ease
  @media (max-width: 640px)
    max-width: unset


.EmptyCheckMarkIcon
  cursor: pointer
  path
    stroke: #000000
    transition: all 0.4 ease
  &:hover
    path
      stroke: #219653
      transition: all 0.4 ease


body::-webkit-scrollbar 
  width: 10px


body::-webkit-scrollbar-track 
  background-color: red


html::-webkit-scrollbar-thumb 
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)
  outline: 1px solid red
.short__menu
  z-index: 1000000
  position: absolute
  top: 28px
  right: 360px
  cursor: pointer
  font-size: 16px
  max-width: 80%
  p
    margin: 0
  ul
    position: absolute
    list-style-type: none
    background: #fff
    padding: 15px
    border-radius: 15px
  li
    margin-top: 10px
    max-width: 280px
    a
      color: black
  li:hover
    text-decoration: underline
.notification__account
  z-index: 1000000
  position: fixed
  top: 60px
  right: 20px
  background: #fff
  border-radius: 10px
  text-align: center
  font-weight: 600
  width: 250px
  padding: 10px
  -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2)
  -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2)
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2)
.update_notifications
  position: fixed
  top: 90px
  left: 280px
  z-index: 9999