.person-list-panel-wrapper
    display: flex
    flex-direction: column
    width: 100%
    align-items: flex-start

    .control-panel-green-button
        background: #219653
        border-radius: 4px

        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: bold
        font-size: 14px
        line-height: 20px
        display: flex
        align-items: center
        text-align: center
        color: #FFFFFF

        box-sizing: border-box
        padding: 18px 12px
        cursor: pointer

        border-radius: 4px
        z-index: 1
        @media (max-width: 640px)
            border-radius: 25px
            padding: 12px
            margin-top: 10px
            z-index: 1
        //     margin: 0 auto
    .disabled-panel-green-button
        opacity: 0.4

    &__control-panel
        display: flex
        flex-direction: row
        justify-content: space-between
        margin-bottom: 26px
        margin-top: -20px
        div
            display: flex
            flex-direction: row
    
    &__persons-wrapper
        display: flex
        flex-direction: row
        flex-wrap: wrap
        @media (max-width: 640px)
            padding-bottom: 100px

        .person-wrapper
            position: relative
            display: flex
            justify-content: center
            align-items: center
            background: rgba(33, 30, 30, 0.5)
            border-radius: 20px
            box-sizing: border-box
            padding: 20px 57px
            margin-right: 15px
            margin-bottom: 16px

            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 12px
            line-height: 16px
            color: #FFFFFF
            z-index: 1

            cursor: pointer
            // @media (max-width: 640px)
            //     padding: 9px 26px
            //     max-height: 153px
            &__number-wrapper
                position: absolute
                z-index: 5
                right: -10px
                top: -10px
                height: 32px
                width: 32px
                border-radius: 50%
                background: #219653
                border: 1px solid #219653
                display: flex
                justify-content: center
                align-items: center

                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: bold
                font-size: 18px
                line-height: 20px
                color: #FFFFFF
            &__edit-wrapper
                position: absolute
                bottom: 1px 
                right: 13px

                height: 32px
                width: 32px
                border-radius: 50%
                background: #F7E38A
                display: flex
                justify-content: center
                align-items: center
                z-index: 998
            &__delete-wrapper
                position: absolute
                top: -10px
                right: 32px

                height: 32px
                width: 32px
                border-radius: 50%
                background: #E97474
                display: flex
                justify-content: center
                align-items: center
                z-index: 990
        .choosen-person-wrapper
            background: #211E1E
    