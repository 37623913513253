.tariffs__component
    padding: 30px 39px 49px 22px
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25)
    background: #fff
    border-radius: 12px
    width: fit-content
    height: fit-content
    margin: 0 15px
    margin-top: 35px
    @media (max-width: 640px)
        margin: 0 
        margin-top: 0
        margin-bottom: 25px

.tariffs__elem
    list-style-type: none
    padding: 0
    li
        display: flex
        align-items: center
        margin: 0
        margin-bottom: 11px
        img
            margin-right: 11px
        p
            font-family: 'Open Sans'
            font-style: normal
            font-weight: 600
            font-size: 16px
            margin: 0
.tariffs__title
    font-family: 'Open Sans'
    font-style: normal
    font-weight: 600
    font-size: 24px
    margin: 0
    margin-bottom: 20px
    margin-left: 14px
.tariffs__description
    font-family: 'Open Sans'
    font-style: normal
    font-weight: 600
    font-size: 20px
    margin: 30px 0
.buy__tariffs_button
    font-family: 'Open Sans'
    font-style: normal
    font-weight: 600
    font-size: 16px
    margin: 0 auto
    border: 2px solid #219653
    border-radius: 12px
    padding: 12px 27px 18px 27px
    background: #fff
    outline: none
    display: block
    transform: translateX(10px)
    cursor: pointer
    transition: 0.3s
.buy__tariffs_button:hover
    background: #219653
    color: #fff
.activ
    font-size: 20px
    color: #fff
    background: green
    width: fit-content
    margin: 0 auto
    padding: 16px 55px
.buy__next
    padding: 0 26px
    font-family: "Open Sans"
    @media (max-width: 640px)
        margin-top: -100px
.buy__next__titles 
    display: flex
    align-items: center
    margin-bottom: 20px
    p
        font-size: 20px
        font-weight: 600
        margin: 0
    span
        font-size: 20px
        margin-left: 10px
.button_buy_next
    display: flex
    button
        padding: 10px 40px
        margin-right: 15px
        width: fit-content
.promo
    margin-bottom: 20px
    @media (max-width: 640px)
        display: flex
    input
        padding: 14px 18px
        font-size: 16px
        outline: none
        border-radius:  12px 0 0 12px 
        border: none
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07)
        @media (max-width: 640px)
            width: 130px

    button
        width: fit-content
        border-radius: 0 12px 12px 0
.buy_next_title
    font-size: 28px
    margin-bottom: 25px
    font-weight: bold
   