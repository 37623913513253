.start-consultation-container
    position: relative
    right: 0
    margin-left: auto
    width: 100%
    height: 100%
    display: flex
    flex-direction: column

    box-sizing: border-box
    padding: 48px 40px 120px 40px

    overflow-y: scroll
    @media (max-width: 640px)
        padding: 24px 22px 140px 22px
        width: 100%
        max-width: 100%
    &::-webkit-scrollbar 
        width: 6px
        height: 5px
        margin: 30px
    &::-webkit-scrollbar-track 
        box-shadow: 0
        border-radius: 5px
        margin: 20px
    &::-webkit-scrollbar-thumb 
        background: #219653
        border-radius: 5px
        height: 10%

    &__title-wrapper
        display: flex
        flex-direction: row
        align-items: center
        width: 100%

        padding: 0
        height: fit-content
        box-sizing: border-box
        justify-content: space-between
        @media (max-width: 640px)
            max-width: 70%
        div
            position: relative
            right: 0

        span
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 36px
            line-height: 24px
            color: #211E1E

            @media (max-width: 640px)
                font-size: 24px
                line-height: 28px

    &__additional-title
        margin-top: 40px
        margin-bottom: 12px
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: bold
        font-size: 24px
        line-height: 28px
        color: #211E1E
        @media (max-width: 640px)
            font-size: 16px
            margin-top: 24px
            margin-bottom: 4px
    &__measurements-list-wrapper
        display: flex
        flex-direction: row
        flex-wrap: wrap
        justify-content: space-between
        @media (max-width: 640px)
            padding-bottom: 62px
        &__measurement-panel
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between

            width: calc(50% - 10px)
            background: #FFFFFF
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07)
            border-radius: 12px
            box-sizing: border-box
            padding: 16px 30px 16px 24px
            margin-top: 20px
            @media (max-width: 640px)
                margin-top: 16px
                width: 100%
            &__info
                display: flex
                flex-direction: column
                div
                    display: flex
                    flex-direction: row
                    font-family: Montserrat, sans-serif
                    font-style: normal
                    font-weight: normal
                    font-size: 18px
                    line-height: 28px
                    color: #9292A3
                    text-overflow: ellipsis
                    @media (max-width: 640px)
                        line-height: 24px
                        font-size: 16px
                    span
                        font-weight: bold
                        color: #211E1E
                        margin-right: 6px
            
            &__delete
                cursor: pointer

        &__add-measurement-panel
            display: flex
            justify-content: center
            align-items: center
            width: calc(50% - 10px)
            background: #FFFFFF
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='rgba(33, 150, 83, 0.54)' stroke-width='2' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")
            box-sizing: border-box
            border-radius: 12px
            height: 116px
            margin-top: 20px

            font-family: Open Sans, sans-serif
            font-style: normal
            font-weight: 600
            font-size: 16px
            line-height: 20px
            text-align: center
            color: #219653

            cursor: pointer

            transition: all .6s ease
            @media (max-width: 640px)
                width: 100%
                height: 88px
            span
                margin-left: 8px

            &:hover
                background: rgba(33, 150, 83, 0.05)
                background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='rgba(33, 150, 83, 0.54)' stroke-width='2' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")

    &__component-title
        margin: 15px 0 8px 0
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: normal
        font-size: 14px
        line-height: 17px
        color: #211E1E
        opacity: 0.5
    &__component
        & > div
            margin: 0
        .start-consultation-container__measurements-list-wrapper__measurement-panel
            margin-bottom: 20px
            @media (max-width: 640px)
                margin-bottom: 12px
    
    &__problem-text
        width: 100%
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: 500
        font-size: 15px
        line-height: 20px
        color: #211E1E
        margin-bottom: 8px