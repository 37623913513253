.measurement-toggle-list
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    box-sizing: border-box

    height: fit-content
    max-height: 50px
    overflow: hidden

    margin-top: 8px
    margin-right: 24px
    max-width: 98%
    overflow: hidden
    
    transition: max-height 1s cubic-bezier(0,0,1,1), border .4s ease

    @media (max-width: 640px)
        width: calc(100% - 6px)
        background: #FFFFFF
        border: 1px dashed #219653
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07)
        border-radius: 12px
    &__header
        margin-top: 4px
        box-sizing: border-box
        padding-bottom: 6px
        width: 100%
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        border-bottom: 2px dashed #219653
        @media (max-width: 640px)
            border: none
            margin: 9px 9px
            width: calc(100% - 18px)
        span
            margin-left: 9px
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 600
            font-size: 16px
            line-height: 20px
            color: #000000
        div
            transition: transform .4s ease-in-out
            margin-right: 9px
            svg
                path
                    fill: rgba(33, 150, 83, 1)
        .rotated-icon
            transform: rotate(180deg)

    
    &__component
        margin-top: 18px
        position: relative
        width: 100%
        div
            width: 100%

.opened-measurement-toggle-list
    height: fit-content
    max-height: fit-content

    @media (max-width: 640px)
        border: 1px solid #219653

.scrollable-toggle-list
    overflow-x: scroll
    @media (max-width: 640px)
        overflow: hidden
    &::-webkit-scrollbar 
        width: 6px
        height: 5px
        margin: 30px
    &::-webkit-scrollbar-track 
        box-shadow: 0
        border-radius: 5px
        margin: 20px
    &::-webkit-scrollbar-thumb 
        background: #219653
        border-radius: 5px
        height: 10%