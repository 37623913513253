.persons-measurement-container
    
    position: relative
    margin-left: auto
    height: 100%
    max-height: calc(100% - 72px)
    display: flex
    flex-direction: column

    overflow-y: scroll
    overflow-x: hidden
    
    box-sizing: border-box
    padding: 30px 24px
    @media (max-width: 640px)
        padding: 30px 20px
        align-items: center
    &::-webkit-scrollbar 
        width: 6px
        height: 5px
        margin: 30px
    &::-webkit-scrollbar-track 
        box-shadow: 0
        border-radius: 5px
        margin: 20px
    &::-webkit-scrollbar-thumb 
        background: #219653
        border-radius: 5px
        height: 10%


.listPersonsBox
    @media ( max-width: 640px )
        width: 100%