.delete-measurement-consultation-modal-wrapper
    position: fixed
    left: 0
    top: 0
    z-index: 999
    width: 100vw
    height: 100vh
    background: rgba(1,1,1,0.3)

    display: flex
    justify-content: center
    align-items: center
    
    .delete-measurement-consultation-modal
        display: flex
        flex-direction: column
        box-sizing: border-box
        padding: 24px 32px 10px 32px
        background: #FFFFFF
        border-radius: 12px
        @media (max-width: 640px)
            width: 90%
        &__title
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 28px
            line-height: 30px
            color: #211E1E
            @media (max-width: 640px)
                text-align: center
        &__row
            display: flex
            flex-direction: row
            margin-top: 22px
            @media (max-width: 640px)
                flex-direction: column
                align-items: center
            &__main
                display: flex
                flex-direction: column
                margin-left: 21px
                @media (max-width: 640px)
                    margin-left: 0
                &__title
                    font-family: Montserrat, sans-serif
                    font-style: normal
                    font-weight: 500
                    font-size: 15px
                    line-height: 20px
                    color: #211E1E
                    opacity: 0.54
                    @media (max-width: 640px)
                        text-align: center
                &__name
                    margin: 5px 0 3px 0
                    font-family: Montserrat, sans-serif
                    font-style: normal
                    font-weight: 500
                    font-size: 15px
                    line-height: 20px
                    color: #211E1E
                    mix-blend-mode: normal
                &__buttons
                    margin-top: 43px
                    display: flex
                    flex-direction: row
                    justify-content: flex-end
                    .green-button
                        max-width: fit-content
                        margin-left: 16px
                    &__delete-button
                        display: flex
                        align-items: center
                        margin-left: 17px
                        box-sizing: border-box
                        padding: 10px 16px
                        text-align: center
                        background: #E3655D
                        border-radius: 12px
                        font-family: Open Sans, sans-serif
                        font-style: normal
                        font-weight: 600
                        font-size: 16px
                        line-height: 20px

                        color: #FFFFFF
                        cursor: pointer

                        transition: all .4s ease
                        &:hover
                            background: #c9544c
