.main-square-graphic
    display: flex
    flex-direction: column
    position: relative
    width: 100%
    margin-right: 10px
    @media (max-width: 640px)
        margin: 0 auto
        margin-bottom: 12px
    .square
        position: relative
        overflow: hidden
        border: 1px solid #0584CB
        box-sizing: border-box
        display: flex
        justify-content: center
        align-items: center
        @media (min-width: 640px)
            background: white
        &__x-axis
            position: absolute
            width: calc(100% - 30px)
            margin: auto 0
            pointer-events: none
            height: 1px
            background: #0000FF
            z-index: 20
        &__y-axis
            position: absolute
            width: 1px
            margin: auto 0
            pointer-events: none
            height: calc(100% - 30px)
            background: #FF0000
            z-index: 20
        &__zero-dot
            position: absolute
            width: 6px
            pointer-events: none
            height: 6px
            border-radius: 50%
            background: #3A3A3A
            z-index: 20
        &__diagonal
            position: absolute
            pointer-events: none
            width: 450px
            height: 1px
            background: #A9A9A9
            transform: rotate(135deg)
            z-index: 20
        &__dots-array
            display: flex
            justify-content: center
            align-items: center
            z-index: 14
            font-family: JetBrains Mono, monospace
            span
                font-family: JetBrains Mono
                font-style: normal
                font-weight: 400
                font-size: 12px
                line-height: 16px
                color: #000000
                z-index: 20
            
            &__y-axis
                position: absolute
                height: 100%
                width: fit-content
                display: flex
                flex-direction: column
                align-items: center
                margin-right: 36px
                z-index: 20
                font-family: JetBrains Mono, monospace
                span
                    position: absolute
                    text-align: end

            &__x-axis
                position: absolute
                width: 100%
                margin: 0 auto
                height: fit-content
                display: flex
                flex-direction: row
                align-items: center
                margin-bottom: 34px
                z-index: 20
                height: fit-content
                font-family: JetBrains Mono, monospace
                span
                    position: absolute
                    pointer-events: none
        &__status-ellipse
            position: absolute
            width: auto
            z-index: 200
            border-radius: 50%
            background: #ACE3B2
            border: 1px solid #06A816
            box-sizing: border-box
            display: flex
            justify-content: center
            align-items: center
            //transform: translate(-50%, 32%) 
            &__dot
                width: 3px 
                height: 3px
                background: #000000
                border-radius: 3px
                margin: auto
                z-index: 101
            &__vertical-line
                position: absolute
                height: 100%
                width: 2px
                background: #92BB97
            &__horizontal-line
                position: absolute
                height: 2px
                width: 100%
                background: #92BB97
        &__mode-1
            width: 100%
            height: 100%
            display: flex
            flex-direction: row
            position: absolute
            z-index: 1
            transition: all .8s ease
            &__left
                position: absolute
                width: calc(50% - 10px)
                height: 100%
                background: #F9E5E5
                box-sizing: border-box
            &__right
                position: absolute
                right: 0
                width: calc(50% - 10px)
                height: 100%
                background: #E5F9E7
                box-sizing: border-box
        &__mode-2
            width: 100%
            height: 100%
            display: flex
            flex-direction: column
            position: absolute
            z-index: 14
            transition: all .8s ease
            &__top
                position: absolute
                width: 100%
                height: calc(50% - 10px)
                background: #E5F9E7 
                box-sizing: border-box

            &__bottom
                position: absolute
                box-sizing: border-box

                bottom: 0
                width: 100%
                height: calc(50% - 10px)
                background: #F9E5E5
        &__mode-3
            width: 100%
            height: 100%
            display: flex
            flex-direction: row
            position: absolute
            z-index: 14
            transition: all .8s ease
            &__left
                width: 0
                height: 0
                border-top: 0px solid transparent
                border-left: 317px solid rgba(255, 143, 143, .5)
                border-bottom: 317px solid transparent 
                z-index: 1
            &__right
                width: 0
                height: 0
                border-style: solid
                border-width: 0 0 318px 318px
                border-color: transparent transparent rgba(146, 189, 255, .5) transparent
                z-index: 1
                margin-left: -316px

        &__mode-4
            width: 100%
            height: 100%
            display: flex
            flex-direction: row
            position: absolute
            z-index: 1
            transition: all .8s ease
            transform: rotate(-90deg)
            &__diagonal
                position: absolute
                width: 447px
                height: 0px
                bottom: 159px
                pointer-events: none
                border: 1px dashed #A9A9A9
                transform: rotate(45deg)
                z-index: 20
                transition: all .8s ease
            &__left
                width: 0
                height: 0
                border-top: 0px solid transparent
                border-left: 317px solid rgba(203, 203, 203, .5)
                border-bottom: 317px solid transparent 
                z-index: 1
            &__right
                width: 0
                height: 0
                border-style: solid
                border-width: 0 0 318px 318px
                border-color: transparent transparent rgba(172, 227, 178, .5) transparent
                z-index: 1
                margin-left: -316px

        &__mode-5
            width: 100%
            height: 100%
            display: flex
            flex-direction: row
            position: absolute
            z-index: 1
            transition: all .8s ease
            &__left-top
                position: absolute
                width: calc(50% - 18px)
                height: calc(50% - 18px)
                left: 0
                top: 0
                background: rgba(249, 229, 229, 1)
                z-index: 1
            &__right-top
                position: absolute
                width: calc(50% - 18px)
                height: calc(50% - 18px)
                top: 0
                background: rgba(229, 249, 231, 1)
                right: 0
                z-index: 1
            &__left-bottom
                position: absolute
                width: calc(50% - 18px)
                height: calc(50% - 18px)
                left: 0
                bottom: 0
                background: rgba(203, 203, 203, 1)
                z-index: 1
            &__right-bottom
                position: absolute
                width: calc(50% - 18px)
                height: calc(50% - 18px)
                background: rgba(208, 233, 248, 1)
                bottom: 0
                right: 0
                z-index: 1
            &__center
                width: 26px
                height: 26px
                position: absolute
                background: #fff
                z-index: 20
                border-radius: 5px
                top: 50%
                left: 50%
                transform: translate(-50%, -50%)
            &__top
                position: absolute
                left: 50%
                transform: translateX(-50%)
                top: 0
                width: 20px
                height: calc(50% - 18px)
                background: linear-gradient(180deg, #EB8C8C 0%, #F9E5E5 100%)
                border-radius: 7px
                z-index: 100
            &__left
                position: absolute
                top: 50%
                transform: translateY(-50%)
                left: 0
                height: 20px
                width: calc(50% - 18px)
                background: linear-gradient(90deg, #B6B6B6 0%, #E5E5E5 100%)
                border-radius: 7px
            &__right
                position: absolute
                top: 50%
                transform: translateY(-50%)
                right: 0
                height: 20px
                width: calc(50% - 18px)
                background: linear-gradient(90deg, #D0E9F8 0%, #92BDFF 100%)
                border-radius: 7px
            &__bottom
                position: absolute
                left: 50%
                transform: translateX(-50%)
                bottom: 0
                width: 20px
                height: calc(50% - 18px)
                background: linear-gradient(180deg, #E8E8E8 0.52%, #B6B6B6 100%)
                border-radius: 7px

        &__first-edge
            position: absolute
            width: calc(100% - 32px)
            height: calc(100% - 32px)
            border: 1px solid #FB62A2
            margin: 16px
            z-index: 15
            display: flex
            justify-content: center
            align-items: center
            pointer-events: none
        &__second-edge
            position: relative
            width: calc(100%  / 3 * 2)
            height: calc(100% / 3 * 2)
            border: 1px solid #4E9555
            margin: 16px
            z-index: 15
            display: flex
            justify-content: center
            align-items: center
            pointer-events: none
        &__third-edge
            position: relative
            width: calc(100% / 2)
            height: calc(100% / 2)
            border: 1px solid #397DFF
            margin: 16px
            z-index: 15
            display: flex
            justify-content: center
            align-items: center
            pointer-events: none
            
    &__header
        position: relative
        height: 40px
        width: 784px !important
        margin-left: -22px
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        box-sizing: border-box
        padding: 0 30px
        overflow: hidden
        @media (max-width: 640px)  
            padding: 0 
            margin-left: 0
            width: 100% !important
        &__state-line
            position: absolute
            width: 729px !important
            overflow: HIDDEN
            display: flex
            flex-direction: row
            align-items: center
            transition: all .8s ease
            @media (max-width: 640px)   
                width: 320px !important 
               
            div
                display: flex
                flex-direction: row
                align-items: center
                transition: all .8s ease
                @media (max-width: 640px) 
                    margin-left: 10px
                    width: fit-content !important
                    
                   
                span
                    min-width: fit-content
                    margin: 0 auto
                    display: flex
                    justify-content: center
                    align-items: center
                    margin: 0 9px
                    font-family: Montserrat, sans-serif
                    font-style: normal
                    font-weight: 500
                    cursor: pointer
                    font-size: 16px
                    line-height: 17px
                    color: #211E1E
                    opacity: 0.7
                      

        &__left
            position: absolute
            z-index: 451
           
            height: 30px
            
            
            
            display: flex
            justify-content: center
            align-items: center
            margin-left: -22px
            cursor: pointer
            transition: opacity .4s ease
            svg
                path
                    stroke: #219653
            @media (max-width: 640px)       
                margin-left: 0
                padding-right: 10px
                background: #fff

        &__right
            position: absolute
            z-index: 451
            
            height: 30px
         
            right: 10px
            display: flex
            justify-content: center
            align-items: center
            cursor: pointer
            transition: opacity .4s ease

            svg
                transform: rotate(180deg)
                path
                    stroke: #219653
            @media (max-width: 640px)       
               right: 0
               background: #fff
               padding-left: 10px
        .disabled
            opacity: 0.4

.points-wrapper
    max-width: calc(100% - 30px)
    height: calc(100% - 30px)
    position: absolute
.dotsFont
    font-size: 12px


.point
    box-sizing: border-box
    z-index: 100
    border-radius: 50%
    border: 1px solid red
    position: absolute
    pointer-events: none
    transition: all .2s ease
    transform: translate(-50%, -50%)

.choosen-partner
    border-width: 3px
.active__mode_1
    border: 2px solid #85A54C
.active__mode_2
    border: 2px solid #A24A4A
.passive__mode
    opacity: 0.5
.activeSlider
    border-bottom: 2px solid #219653
    font-weight: bold !important
    color: black !important
    opacity: 1 !important
.nonShow
    filter: blur(7px)
    pointer-events: none

.show__to__buye
    display: flex
    flex-direction: column
    align-items: center
    background:#fff
    position: absolute
    z-index: 500
    width: 210px
    padding: 20px
    border-radius: 10px
    left: 86px
    top: 157px
    font-size: 16px
    text-align: center
    width: 220px !important
.noShow
    display: none
@keyframes points 
    0% 
        transform: scale(1) translate(-50%, -50%)

    50% 
        transform: scale(1.5) translate(-33.3%, -33.3%)

    100% 
        transform: scale(1) translate(-50%, -50%)
        


