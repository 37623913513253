.vertical-step-bar-wrapper
    width: 100%
    max-width: 352px
    display: flex
    flex-direction: column
    position: relative
    @media (max-width: 640px) 
        display: none

    &__step-container
        display: flex
        flex-direction: row
        align-items: center
        margin-bottom: 24px
        z-index: 32
        position: relative

        .step-bar-circle
            display: flex
            align-items: center
            justify-content: center
            font-family: Open Sans, sans-serif
            font-style: normal
            font-weight: 600
            font-size: 12px
            line-height: 20px
            box-sizing: border-box
            width: 32px
            height: 32px
            border-radius: 32px
            position: relative
            margin-right: 16px
        .step-bar-cursor
            cursor: pointer
        .step-bar-title
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 16px
            line-height: 28px
            color: #211E1E

        .active
            color: #FFFFFF
            background: #219653
            border: 1px solid#219653

        .inactive
            color: #9292A3
            border: 1px solid #9292A3
            background: #F4F4F4

    .vertical-step-bar-connecting-line
        position: absolute
        left: 16px
        height: 86px
        width: 1px
        background: #9292A3
        z-index: 1
.pop_up_warning
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background: rgba(0, 0, 0, 0.3)
    z-index: 100
    display: flex
    justify-content: center
    align-items: center
.pop__up__warning__body
    background: #fff
    padding: 20px
    border-radius: 15px
    width:400px
    p
        font-size: 20px
        text-align: center
        font-weight: bold
        margin: 10px 0
    >div
        display: flex
        justify-content: center
        margin-top: 15px
    .white-button
        width: fit-content
    .green-button
        width: fit-content
        margin-left: 10px