.person-choose-panel
    display: flex
    flex-direction: row
    align-items: center
    background: #FFFFFF
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07)
    border-radius: 12px
    padding: 12px
    margin: 8px 0
    .pop_up_warning
        right: 0
        width: 1660px
        left: unset
    @media (max-width: 640px)
        flex-direction: column
    
    &__wrap
        display: flex
        width: 40%
        @media (max-width: 640px)
            flex-direction: row
            align-items: flex-start
            justify-content: flex-start
            margin-right: auto
    &__check-mark
        transform: scale(0.75)
        cursor: pointer

    &__person-icon
        transform: scale(0.75)
      

    &__name-column
        max-width: 60%
        display: flex
        flex-direction: column
        margin-left: 12px
        @media (max-width: 640px)
            max-width: 100%
            margin-right: auto
        div
            font-family: 'Montserrat', sans-serif
            font-style: normal
            font-weight: 700
            font-size: 18px
            line-height: 28px
            color: #211E1E
        span
            margin-top: -4px
            font-family: 'Montserrat', sans-serif
            font-style: normal
            font-weight: 500
            font-size: 16px
            line-height: 20px
            width: 270px
            color: #211E1E
            opacity: 0.34
    
    &__additional-data
        display: flex
        flex-direction: row
        align-items: center
        margin-left: 14px
        @media (max-width: 640px)
            flex-direction: row
            align-items: flex-start
            justify-content: flex-start
            margin-right: auto
            margin-left: 0
            margin: 8px auto 8px 0
        &__column
            display: flex
            flex-direction: column
            margin-right: 32px
            div
                font-family: 'Montserrat', sans-serif
                font-style: normal
                font-weight: 500
                font-size: 14px
                line-height: 28px
                color: #211E1E
                opacity: 0.54
            span
                margin-top: -8px
                font-family: 'Montserrat', sans-serif
                font-style: normal
                font-weight: 500
                font-size: 18px
                line-height: 28px
                color: #211E1E
    
    &__icons
        display: flex
        flex-direction: row
        align-items: center
        margin-left: auto
        @media (max-width: 640px)
            align-items: flex-end
            justify-content: flex-end
            position: relative
            margin-left: auto
            margin-right: 0
        div
            margin-right: 12px
            margin-bottom: -5px
        svg
            cursor: pointer
            transform: scale(0.85)