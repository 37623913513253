.big-instruction-page-what-to-do-wrapper
    //max-width: 364px
    width: 50vw
    position: relative
    display: flex
    flex-direction: column

    background: #FFFFFF
    border-radius: 20px

    box-sizing: border-box
    padding: 16px 22px
    
    margin-left: 28px
    margin-right: 31px

    @media screen and ( max-width: 640px )
        width: calc(100vw - 18px)
        margin: 0 auto

    font-family: Montserrat, sans-serif
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 18px
    color: #000000

    &__title
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: bold
        font-size: 18px
        line-height: 22px
        color: #211E1E
        margin-bottom: 6px



