.landing__body 
    background: #dce8e1
    display: flex
    flex-direction: column
    align-items: center
    ul  
        list-style-type: none
        padding: 0
        li
            display: flex
            p
                font-family: 'Open Sans'
                font-style: normal
                font-weight: 400
                font-size: 16px
                color: #211E1E
                @media (max-width: 640px)
                      font-size: 14px
          

.landing__body *
    box-sizing: border-box
    font-family: 'Open Sans'
    margin: 0
    padding: 0
.landing__header
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 24px
    margin-bottom: 76px
    @media (max-width: 640px) 
        width: 100%
        margin-bottom: 48px
    .landing__nav
        p
            font-family: 'Open Sans'
            font-style: normal
            font-weight: 700
            font-size: 16px
            margin-left: 14px
            width: 275px
            margin-right: 113px

.landing__nav
    display: flex
    align-items: center
    background: #FFFFFF
    border-radius: 24px
    margin-bottom: 58px
    padding: 14px 22px
    ul
        list-style-type: none
        display: flex
        margin-right: 221px
        li
            margin-right: 12px
        a
            border: 1px solid #DCE8E1
            border-radius: 12px
            padding: 14px
            display: flex
            transition: 0.5s
        a:hover
            background: #dce8e1
            transform: translateY(-5px)
    .landing__auth
        font-style: normal
        font-weight: 600
        font-size: 16px
        color: #fff
        background: #219653
        padding: 9px 22px
        border: 2px solid #219653
        border-radius: 12px
        cursor: pointer
        margin-left: 9px
    .landing_registration
        border: 2px solid #219653
        border-radius: 12px
        font-style: normal
        font-weight: 600
        font-size: 16px
        padding: 9px 22px
        cursor: pointer
        color: black
.header__body
    display: flex
    @media (max-width: 640px)
       flex-direction: column
       width: 100%
    h1
        font-family: 'Montserrat'
        font-style: normal
        font-weight: 800
        font-size: 58px
        color: #211E1E
        margin-bottom: 16px
        @media (max-width: 640px)
            font-size: 32px
            text-align: center
    ul
        @media (max-width: 640px)
            width: 92%
            margin: 0 auto
    li
        display: flex
        align-items: center
        margin-bottom: 12px
        @media (max-width: 640px)
            
        img
            margin-right: 12px
        p
            margin: 0
.header__body__content
    margin-right: 30px    
    @media (max-width: 640px)   
        margin: 0    
        margin-top: 120px
.mobil__header__button
    font-style: normal
    font-weight: 600
    font-size: 16px
    color: #fff
    background: #219653
    padding: 20px 22px
    width: 92%
    border-radius: 12px
    cursor: pointer
    margin: 0 auto
    text-align: center
    margin-top: 24px
.header__body__img
    img
        @media (max-width: 640px) 
            width: 92%
            display: block
            margin: 0 auto
            margin-top: 32px

.header__body__text
    background: url(./img/fon.svg)
    margin-top: 40px
    width: 574px
    padding: 32px 33px
    background-size: 100%
    background-repeat: no-repeat
    border-radius: 24px
    @media (max-width: 640px)
       display: none 
   
    h3
        font-style: normal
        font-weight: 700
        font-size: 18px
        margin-bottom: 10px
        span
            color: #219653
    p
        font-style: normal
        font-weight: 400
        font-size: 14px
        span
            color: #219653
            font-weight: 600
    a
        padding: 20px 32px
        background: #219653
        border-radius: 12px 
        width: fit-content
        color: #fff
        font-style: normal
        font-weight: 600
        font-size: 16px
        margin: 0 auto
        transform: translateY(40px)
        cursor: pointer
        transition: 0.3s
        display: block
    a:hover
        transform: translateY(40px) scale(1.05)      
.coron
    animation: coron 4s infinite
.landing__video
    @media (max-width: 640px)
       width: 92%
   
    h2
        font-family: 'Montserrat'
        font-style: normal
        font-weight: 800
        font-size: 56px
        margin-bottom: 12px
        @media (max-width: 640px)
            text-align: center
            font-size: 30px
    p
        font-style: normal
        font-weight: 400
        font-size: 16px
        margin-bottom: 40px
        @media (max-width: 640px)
            font-size: 14px
            text-align: center
       
.video__container
    background: url(./img/video.png)
    display: flex
    align-items: center
    justify-content: center
    background-repeat: no-repeat
    background-size: cover
    width: 1216px
    height: 682px
    margin-bottom: 144px
    @media (max-width: 640px)
        width: 100%
        height: auto
        background-size: contain
        margin-bottom: 41px
    img
        transition: 0.5s
        cursor: pointer
        @media (max-width: 640px)
            width: 80px
            margin: 62px 0
    img:hover
        transform: scale(1.1)
.landing__problems
    width: 1217px
    @media (max-width: 640px)
        width: 100%
    h2
        font-family: 'Montserrat'
        font-style: normal
        font-weight: 800
        font-size: 56px
        margin-bottom: 83px
        @media (max-width: 640px)
            font-size: 30px
            text-align: center
        
    .landing__problems_row
        display: flex
        justify-content: space-between
        margin-bottom: 170px
        @media (max-width: 640px)
            flex-direction: column
            align-items: center
            margin-bottom: 0
    .landing__problems_em
        width: 384px
        background: #fff
        border-radius: 20px
        padding: 30px
        position: relative
        height: 292px
        @media (max-width: 640px)
            width: 92%
            margin-bottom: 100px
        img
            transform: translateY(-75px)
        h3
            font-family: 'Montserrat'
            font-style: normal
            font-weight: 700
            font-size: 22px
            margin-bottom: 12px
            margin-top: -50px
        p
           
            font-style: normal
            font-weight: 400
            font-size: 12px
          
            color: #5B5B5B
        a
            padding: 20px 32px
            background: #219653
            border-radius: 12px 
            width: fit-content
            color: #fff
            font-style: normal
            font-weight: 600
            font-size: 16px
            margin: 0 auto
            position: absolute
            bottom: -30px
            left: 50%
            transform: translateX(-50%)
            cursor: pointer
            transition: 0.5s
        a:hover
            transform: translateY(-5px) translateX(-50%)
.social__networks
    position: relative
    .first_sphere
        position: absolute
        right: -517px
        top: -100px
        animation: rotateMe 60s linear infinite
        @media (max-width: 640px)
        
            right: 4%
            top: 477px
        
            width: 92%

.social__networks__content
   
    ul
        @media (max-width: 640px)
            display: block
            column-count: 2
            width: 85%
            margin: 0 auto
            margin-top: 22px
    h2
        font-family: 'Montserrat'
        font-style: normal
        font-weight: 800
        font-size: 56px
        margin-bottom: 24px
        @media (max-width: 640px)
            font-size: 30px
            text-align: center
    >p
       
        font-style: normal
        font-weight: 700
        font-size: 16px
        margin-bottom: 24px
        @media (max-width: 640px)
            font-size: 14px
            text-align: center
            width: 92%
            margin: 0 auto
    li
        display: flex
        align-items: center
        margin-bottom: 12px
        img
            margin-right: 12px
        p
            font-style: normal
            font-weight: 400
            font-size: 16px
      
.social__networks
    display: flex
    width: 1217px
    @media (max-width: 640px)
        width: 100%
        flex-direction: column
.social__networks__icons
    position: relative
    z-index: 3
    img
        @media (max-width: 640px)
            width: 86%
            display: block
            margin: 0 auto
            margin-top: 25px
.circle2
    position: absolute
    left: -610px
    top: 260px
    animation: scaleMe 5s ease-in-out infinite
.landing__accounts
    position: relative
    display: flex
    width: 1217px
    margin-top: 180px
    align-items: flex-start
    @media (max-width: 640px)
        width: 100%
        flex-direction: column
        margin-top: 43px
    h2
        font-family: 'Montserrat'
        font-style: normal
        font-weight: 800
        font-size: 56px
        margin-bottom: 24px
        @media (max-width: 640px)
            font-size: 30px
            text-align: center
            width: 92%
            margin: 0 auto
            margin-bottom: 20px
    .gray_line
        width: 240px
        height: 4px
        background: #DCE8E1
    .basic
        background: url(./img/basic.svg)
        background-repeat: no-repeat
        background-size: contain
        padding:40px
        margin-left: 72px
        width: 320px
        padding-bottom: 30px
        @media (max-width: 640px)
            margin: 0 auto
            margin-bottom: 12px
            background-image: url(./img/mob1.svg)
            padding-bottom: 60px
            z-index: 2
    h3
        font-style: normal
        font-weight: 700
        font-size: 24px
        margin-bottom: 40px
    li
        font-style: normal
        font-weight: 400
        font-size: 12px
        padding-bottom: 12px
        margin-bottom: 12px
        border-bottom: 1px solid #DCE8E1
        width: 240px
    li:last-of-type
        border-bottom: none
    .price
        font-style: normal
        font-weight: 700
        font-size: 20px
        color: #219653
        text-align: center
        margin-top: 20px
        font-family: 'Open Sans'
        span
            font-style: normal
            font-weight: 400
            font-size: 20px
            color: #96CDAE
    .premium
        background: url(./img/premium.svg)
        background-repeat: no-repeat
        background-size: contain
        padding: 40px
        margin-left: 33px
        @media (max-width: 640px)
            margin: 0 auto
            z-index: 2
        >div:first-of-type
            display: flex
            justify-content: space-between
            align-items: center
            margin-bottom: 40px
        h3
            color: #219653
            margin-bottom: 0
        .price
            font-size: 28px
            margin-top: 8px
.mobile__header
    display: flex
    flex-direction: column
    align-items: center
    background: #fff
    position: fixed
    top: 0
    left: 0
    width: 100%
    justify-content: space-between
    padding: 20px 4%
    border-radius: 0 0 25px 25px 
    z-index: 200000
    .content__part
        display: flex
        flex-direction: column
        align-items: center
       
        width: 100%
        transition: 0.5s
        overflow: hidden
    .landing_registration
        font-size: 14px
        width: 92%
        font-weight: 600
        border: 2px solid #219653
        border-radius: 12px
        padding: 14px 0
        text-align: center
    .landing__auth
        font-size: 14px
        width: 92%
        color: #fff
        font-weight: 600
        background: #219653
        border: 2px solid #219653
        border-radius: 12px
        padding: 14px 0
        text-align: center
        margin-top: 8px
        margin-bottom: 20px
    .visible__part
        display: flex
        justify-content: space-between
        align-items: center
        width: 100%
        > img
            width: 8vw
    div
        display: flex
        > img
            width: 14vw
            margin-right: 3vw
        p
            font-weight: 700
.mobile__menu
    ul
        margin: 10vh 0
        display: flex
        flex-direction: column
        align-items: center
        li
            text-align: center
        
    a
        font-size: 16px
        color: black
    .gray__line
        width: 12vw
        height: 1px
        background: #DCE8E1
        margin: 2.5vh auto
.mobile__header__ul
    display: flex
    margin-bottom: 10vh
    li
        margin: 0 5px
    a
        width: 52px
        height: 52px
        border: 1px solid #DCE8E1
        border-radius: 12px
        display: flex
        align-items: center
        justify-content: center
.landing__questions
    width: 1217px
    margin-bottom: 130px
    @media (max-width: 640px)
        width: 100%
        margin-bottom: 48px
        margin-top: 20px
    h2
        font-family: 'Montserrat'
        font-style: normal
        font-weight: 800
        font-size: 56px
        margin-bottom: 8px
        @media (max-width: 640px)
            font-size: 30px
            text-align: center
    >p
        font-style: normal
        font-weight: 400
        font-size: 16px
        margin-bottom: 16px
        @media (max-width: 640px)
            width: 80%
            font-size: 14px
            text-align: center
            margin: 0 auto
    ul
        display: flex
        margin-bottom: 40px
        @media (max-width: 640px)
            justify-content: center
            margin-top: 16px
    li
        a
            width: 44px
            height: 44px
            background: #fff
            display: flex
            align-items: center
            justify-content: center
            border-radius: 12px
            margin-right: 12px
            transition: 0.5s
        a:hover
            transform: translateY(-5px)
.button__link__lc
    padding: 20px 32px
    background: #219653
    border-radius: 12px 
    width: fit-content
    color: #fff
    font-style: normal
    display: block
    font-weight: 600
    font-size: 18px
    margin-top: 80px
    position: relative
    z-index: 2
    cursor: pointer
    transition: 0.3s
.button__link__lc:hover
    transform: scale(1.05)
.questions__body
    display: flex
    justify-content: space-between
    @media (max-width: 640px)
        flex-direction: column
        width: 92%
        margin: 0 auto
    .question__em
        padding: 24px 16px 24px 30px
        background: #fff
        border-radius: 20px
        width: 592px
       
        margin-bottom: 10px
       
        cursor: pointer
        @media (max-width: 640px)
            width: 100%
        div
            display: flex
            align-items: center
            justify-content: space-between
        p
            font-style: normal
            font-weight: 700
            font-size: 18px
        .height__question
            font-style: normal
            font-weight: 400
            font-size: 14px
            transition: 0.5s
            color: #625B5B
            padding-left: 16px
            border-left: 3px solid #219653
            overflow: hidden
           
                
.landing__rewiews
    width: 1217px
    margin-bottom: 168px
    @media (max-width: 640px)
        width: 100%
        margin-bottom: 69px
    h2
        font-family: 'Montserrat'
        font-style: normal
        font-weight: 800
        font-size: 56px
        margin-bottom: 48px
        @media (max-width: 640px)
            font-size: 30px
            text-align: center
            margin-bottom: 79px
    .landing__reviews__row
        display: flex
        @media (max-width: 640px)
            flex-direction: column
            align-items: center
    .landing__reviews__em
        background: #fff
        padding: 24px
        border-radius: 20px
        display: flex
        align-items: flex-start
        margin-right: 56px
        margin-bottom: 32px
        @media (max-width: 640px)
            margin: 0 auto
            width: 92%
            flex-direction: column
            align-items: center
            margin-bottom: 71px
        h4
            font-style: normal
            font-weight: 700
            font-size: 16px
            color: #211E1E
        div
            font-style: normal
            font-weight: 400
            font-size: 14px
            color: #625B5B
            @media (max-width: 640px)
                text-align: center
        p
            font-style: normal
            font-weight: 400
            font-size: 16px
            margin-top: 16px
            color: #211E1E
    .reviews__em
        margin-left: -50px
        margin-right: 16px
        @media (max-width: 640px)
            margin: 0
            margin-top: -70px
            margin-bottom: 32px
    .button__next__landing
        display: flex
        border: 2px solid #96CDAE
        padding: 20px 32px
        color: #219653
        border-radius: 12px
        font-style: normal
        font-weight: 600
        font-size: 16px
        width: fit-content
        margin: 0 auto
        cursor: pointer
        transition: 0.5s
        img
            transition: 0.5s
            margin-right: 10px
    .button__next__landing:hover
        background: #fff
    .button__next__landing:hover img
        transform: rotate(360deg)
.portal
    div
        font-style: normal
        font-weight: 400
        font-size: 16px
    p
        font-style: normal
        font-weight: 600
        font-size: 16px
        color: #219653
        cursor: pointer
    p:hover
        text-decoration: underline
footer
    width: 1217px
    @media (max-width: 640px)
        width: 100%
.footer__content
    display: flex  
    width: 100%
    margin-bottom: 40px
    justify-content: space-between
    align-items: flex-start
    @media (max-width: 640px)
        flex-direction: column
        align-items: center
    .portal
        @media (max-width: 640px)
            text-align: center
            margin-top: 40px
            margin-bottom: 32px

    >ul
        display: flex
        a
            width: 44px
            height: 44px
            background: #fff
            display: flex
            align-items: center
            justify-content: center
            border-radius: 12px
            margin-right: 12px
            transition: 0.5s
        a:hover
            transform: translateY(-5px)

.logo__footer
    display: flex 
    align-items: center
    p
        font-style: normal
        font-weight: 700
        font-size: 16px
        margin-left: 14px
.footer__links__landing
    @media (max-width: 640px)
        margin-bottom: 48px
    li
        font-style: normal
        font-weight: 400
        font-size: 16px
        margin-bottom: 16px
        @media (max-width: 640px)
            text-align: center
            justify-content: center
        a
            color: #211E1E
            @media (max-width: 640px)
                text-align: center
        a:hover
            text-decoration: underline  
.links__down
    width: 100%
    display: flex
    justify-content: space-between
    padding: 24px 0
    border-top: 1px solid #C1D3C9
    @media (max-width: 640px)
        flex-direction: column
        align-items: center
        width: 92%
        margin: 0 auto
    a
        font-style: normal
        font-weight: 400
        font-size: 14px 
        color: black
        cursor: pointer
        @media (max-width: 640px)
            margin-bottom: 16px
    a:hover
        text-decoration: underline    
@keyframes rotateMe 
    0%
        transform: rotate(0)
    100%
        transform: rotate(360deg)
@keyframes scaleMe 
    0%
        transform: scale(1)
    50%
        transform: scale(0.97)
    100%
        transform: scale(1)
@keyframes coron
    0%
        transform: scale(1)
    25%
        transform: scale(1.2)
    50%
        transform: scale(1)
    100%
        transform: scale(1)