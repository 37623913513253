.small-instruction-page-what-to-do-wrapper
    //max-width: 364px
    width: 25vw
    position: relative
    display: flex
    flex-direction: column

    background: #FFFFFF
    border-radius: 20px

    box-sizing: border-box
    padding: 16px 22px
    
    @media screen and ( max-width: 640px )
        width: calc(100vw - 18px)
        margin: 0 auto
        margin-bottom: 15px

    &__title
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: bold
        font-size: 18px
        line-height: 22px
        color: #211E1E

    &__description
        margin-top: 7%
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: normal
        font-size: 14px
        line-height: 18px
        color: #000000

    &__text-wrapper
        display: flex
        flex-direction: column
        position: relative
        margin-top: 4.2%
        .vertical-line
            z-index: 1
            position: absolute
            height: 80%
            width: 1px
            background: #9292A3
            left: 16px
            @media screen and ( max-width: 640px )
                height: 90%
        &__panel
            display: flex
            flex-direction: row
            height: fit-content
            max-height: 100%
            position: relative
            margin-bottom: 4.2%
            @media screen and ( max-width: 640px )
                margin-bottom: 0px
            div
                display: flex
                justify-content: center
                align-items: center
                position: relative
                height: 32px
                width: 36px
                max-height: 32px
                max-width: 36px
                border-radius: 50%
                background: white
                box-sizing: border-box
                border: 1px solid #9292A3
                z-index: 3

                font-family: Open Sans, sans-serif
                font-style: normal
                font-weight: 600
                font-size: 12px
                line-height: 20px
                color: #9292A3

            span 
                font-family: Roboto, sans-serif
                font-style: normal
                font-weight: normal
                font-size: 14px
                line-height: 18px
                color: #000000
                margin-left: 11px
                width: 100%
            
                