.account-finance-panel
    display: flex
    width: 100%
    flex-direction: row
    align-items: center
    height: 76px
    margin: 8px 0
    background: #FFFFFF
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07)
    border-radius: 12px
    box-sizing: border-box
    padding: 10px 20px
    @media (max-width: 640px)
        flex-direction: column
        height: 135px
        padding: 10px 17px
        & > div
            width: 100%
            display: flex
            flex-direction: column
            align-items: flex-start
            justify-content: space-between
    &__column
        display: flex
        flex-direction: column
        margin-right: 7%
        @media (max-width: 640px)
            flex-direction: row
            align-items: center
        &__title
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 500
            font-size: 14px
            line-height: 28px
            opacity: 0.54
            @media (max-width: 640px)
                margin-right: 13px
        &__data
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 500
            font-size: 18px
            line-height: 20px
    &__name
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: bold
        font-size: 18px
        line-height: 28px
        max-height: 56px

        overflow: hidden
        text-overflow: ellipsis
        @media (max-width: 640px)
            max-height: 84px
            text-align: start
            margin-right: auto
          
.account-finance-panel.plus
    color: #219653

.account-finance-panel.minus
    color: rgba(33, 30, 30, 0.43)