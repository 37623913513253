.personal-energy-diagram
    width: 100%
    display: flex
    flex-direction: row
    align-items: flex-start
    justify-content: space-around
    margin: 15px 0
    @media (max-width: 1070px)
        flex-direction: column
        align-items: center
    &__diagram-wrapper
        max-width: 362px
        &__field
            width: 100%
            height: 642px
            background: #E1E1E1
            border: 1px solid #929292
            border-radius: 15px
            display: flex
            flex-direction: column
            align-items: center
            position: relative
            box-sizing: border-box
            padding: 0
            &__green-lines
                height: 100%
                width: 100%
                position: absolute
                display: flex
                flex-direction: column
                align-items: center
                div
                    width: calc(100% - 30px)
                    border: 1px dashed #3F7F3F
            &__grey-lines
                max-width: calc(100% - 30px)
                height: calc(100% - 160px)
                margin: auto 
                display: flex
                flex-direction: column
                align-items: center
                box-sizing: border-box
                //padding-top: 36px
                div
                    border-top: 1px dashed #3F7F3F
                    margin-bottom: 36px
            &__values
                position: absolute
                height: 100%
                left: 14px
                display: flex
                flex-direction: column
                align-items: center
                font-family: JetBrains Mono, monospace
                font-style: normal
                font-weight: 700
                font-size: 18px
                line-height: 24px
                color: #000000                
                    
            &__positive-panel
                position: absolute
                z-index: 3
                left: 50px
                width: 120px
                max-width: 120px
                background: #6CB4EE
                border-radius: 20px
                display: flex
                flex-direction: column
                align-items: center
                justify-content: center
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: 700
                font-size: 18px
                line-height: 22px
                color: #000000
                div
                    width: 100%
                    position: relative
                    text-align: center
                    font-weight: 700
                    color: white
            &__negative-panel
                position: absolute
                z-index: 3
                right: 50px
                width: 120px
                max-width: 120px
                background: #FF816A
                border-radius: 20px
                display: flex
                flex-direction: column
                align-items: center
                justify-content: center
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: 700
                font-size: 18px
                line-height: 22px
                color: #000000
                div
                    width: 100%
                    position: relative
                    text-align: center
                    font-weight: 700
                    color: white
        span
            margin-top: 24px
            width: 100%
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 400
            font-size: 16px
            line-height: 20px

            color: #000000