.consultation-page-wrapper
    position: relative
    right: 0
    margin-left: auto
    // width: auto
    height: 100%
    max-height: 100%
    display: flex
    flex-direction: column
    overflow: hidden

    &::-webkit-scrollbar 
        width: 6px
        height: 5px

    &::-webkit-scrollbar-track 
        box-shadow: 0
        border-radius: 5px

    &::-webkit-scrollbar-thumb 
        background: #219653
        border-radius: 5px
        height: 10%

    &__title
        margin: 40px 24px 24px 24px
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: 700
        font-size: 36px
        line-height: 24px
        color: #211E1E
        @media (max-width: 640px)
            font-size: 24px
            line-height: 28px
    &__navbar
        width: 100%
        margin: 32px
        display: flex
        flex-direction: row
        border-bottom: 2px solid #FFFFFF
        box-sizing: content-box
        padding-bottom: 2px
        @media (max-width: 640px)
            margin: 0 24px 24px 24px
            width: calc(100% - 48px)
        & > span
            position: relative

            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 700
            font-size: 18px
            line-height: 28px
            color: #211E1E
            

            display: flex
            flex-direction: row
            align-items: center

            box-sizing: border-box
            margin-right: 32px

            cursor: pointer
            .number
                position: relative
                margin-left: 8px
                width: 20px
                height: 20px
                border-radius: 50%
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: 500
                font-size: 13px
                line-height: 16px
                display: flex
                align-items: center
                justify-content: center
                color: #FFFFFF
                background: #219653
            .choosen-border
                position: absolute
                bottom: 0
                width: 100%
                height: 4px
                background: #219653
                border-radius: 2px 2px 0px 0px
                margin-bottom: -4px
    .second-row
        margin-top: 0px
    &__consultations-list
        position: relative
        display: flex
        flex-direction: column
        box-sizing: border-box
        padding: 0 24px
        overflow-y: scroll
        width: 100%
        height: 100%
        &::-webkit-scrollbar 
            width: 6px
            height: 5px

        &::-webkit-scrollbar-track 
            box-shadow: 0
            border-radius: 5px

        &::-webkit-scrollbar-thumb 
            background: #219653
            border-radius: 5px
            height: 10%


