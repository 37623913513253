.psychical-process-diagram
    @media (max-width: 640px)
        max-width: calc(100% - 18px)
        margin: 10px 9px
    &__diagram
        position: relative
        width: 100%
        max-width: 1040px
        margin: 32px auto 
        background: #E1E1E1
        border: 1px solid #929292
        border-radius: 15px
        &__lines
            width: 40px
            max-width: calc(100% - 84px)
            height: calc(100% - 92px)
            margin: 46px 42px
            position: absolute
            display: flex
            flex-direction: column
            align-items: center
            justify-content: space-between
            & > div
                width: 100%
                border: 0.5px dashed #3F7F3F
            @media (max-width: 640px)
                max-width: calc(100% - 42px)
                height: calc(100% - 46px)
                margin: 23px 21px
        &__percentages
            position: absolute
            max-width: 40px
            display: flex
            flex-direction: column
            align-items: center
            margin-left: 42px
            margin-top: 20px
            height: 100%
            @media (max-width: 640px)
                margin-left: 21px
                margin-top: 0px
            & > div
                margin-bottom: 86px

                font-family: JetBrains Mono, monospace
                font-style: normal
                font-weight: 700
                font-size: 18px
                line-height: 24px
                color: #000000
                @media (max-width: 640px)
                    margin-bottom: 94px
        &__columns
            position: relative
            width: calc(100% - 160px)
            max-width: calc(100% - 160px)
            height: calc(100% - 92px)
            margin: auto
            margin-top: 46px
            display: flex
            flex-direction: row
            align-items: flex-end
            overflow: hidden
            z-index: 100
            @media (max-width: 640px)
                width: calc(100% - 80px)
                max-width: calc(100% - 90px)
                height: calc(100% - 46px) 
                margin-top: 23px
            & > div
                height: 100%
                min-width: 25%
                border-radius: 10px 10px 0px 0px

    &__decription
        display: flex
        flex-direction: column
        span
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 700
            font-size: 18px
            line-height: 28px
            color: #000
            margin-bottom: 4px
        div
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 400
            font-size: 16px
            line-height: 20px

            color: #000000



        

