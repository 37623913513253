.choose-person-statuses-wrapper
    display: flex
    flex-direction: column
    width: fit-content
    position: relative
    @media (max-width: 640px)
        margin: 0 auto
    &__top-side
        display: flex
        flex-direction: row
        background: #FFFFFF
        width: fit-content
        border-radius: 20px
        box-sizing: border-box
        padding: 3px 2.5px
        div
            display: flex
            align-items: center
            justify-content: center
            height: 50px
            max-width: 150px
            background: #F4F4F4
            border-radius: 20px
            box-sizing: border-box
            padding: 0 10px
            margin: 0 2.5px
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: normal
            font-size: 12px
            line-height: 16px
            color: #211E1E
            cursor: pointer
        .choosen-status-panel
            background: #211E1E
            font-weight: bold
            color: #FFFFFF
    &__bottom-side

        &__description-wrapper
            position: relative
            display: flex
            flex-direction: column
            align-items: center
            z-index: 1
            max-width: 300px
            margin-left: 0 !important
            
            div
                position: absolute
                margin: 4px auto -4px auto
            span
                position: absolute
                min-width: 300px
                background: #FFFFFF
                border-radius: 20px
                box-sizing: border-box
                padding: 8px 16px
                width: fit-content
                max-width: 246px
                text-align: center
                margin-top: 20px

.adaptive-choose-person-wrapper
    width: 100%
    max-width: 538px
    border-radius: 20px
    position: relative
    z-index: 900
    .title
        @media (max-width: 640px)
            font-weight: bold !important
    &__input-wrapper
        width: 100%
        background: #FFFFFF
        box-sizing: border-box
        padding: 14px 12px 5px 12px
        display: flex
        flex-direction: row
        align-items: center
        justify-content: center
        @media (max-width: 640px)
            padding: 14px 12px 14px 12px
        div
            width: 100%
            box-sizing: border-box
            padding-bottom: 9px
            padding-left: 5px
            border-bottom: 1px solid rgba(0, 0, 0, 0.2)
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 14px
            line-height: 16px
            color: #211E1E
            @media (max-width: 640px)
                padding-bottom: 0
                border-bottom: none
        span
            width: fit-content
            position: absolute
            right: 17px
            top: 17px
            transition: all .4s ease
            @media (max-width: 640px)
                top: 12px
        .rotated
            transform: rotate(180deg)
            @media (max-width: 640px)
                width: auto !important
    
    &__modal-wrapper
        position: absolute
        top: 40px
        left: 0px
        width: 100%
        max-width: 538px
        height: 0px
        max-height: 200px
        overflow: hidden
        background: #FFFFFF
        transition: height 1s ease
        border-radius: 0 0 20px 20px
        box-sizing: border-box
        display: flex
        flex-direction: column
        overflow-y: scroll

        border-top: none
        &::-webkit-scrollbar 
            width: 6px
            height: 5px
            margin: 30px
        &::-webkit-scrollbar-track 
            box-shadow: 0
            border-radius: 5px
            margin: 20px
        &::-webkit-scrollbar-thumb 
            background: #219653
            border-radius: 5px
            height: 10%
        div
            margin: 0 17px 4px 7px 
            display: flex
            flex-direction: column
            .title
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: normal
                font-size: 14px
                line-height: 16px
                color: #211E1E
            .desc
                margin-left: 40%
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: normal
                font-size: 12px
                line-height: 16px
                color: rgba(33, 30, 30, 0.7)
                @media (max-width: 640px)
                    margin-left: 1%
    .choose-role-modal-opened
        height: fit-content
        border: 1px solid rgba(0, 0, 0, 0.3)
        border-top: none
