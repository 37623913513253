.consultation-panel
    position: relative
    width: 100%
    display: flex
    flex-direction: row
    align-items: center
    box-sizing: border-box
    padding: 16px 24px
    margin-bottom: 16px
    background: #FFFFFF
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07)
    border-radius: 12px
    @media (max-width: 640px)
        flex-direction: column
        align-items: flex-start
        padding: 16px

    &__title-wrapper
        display: flex
        flex-direction: row
        align-items: center
        width: 40%
        @media (max-width: 640px)
            width: 100%
            
        div
            width: 44px 
            height: 44px 
            border-radius: 50%
            background-color: rgba(0, 0, 0, 0.07)
            overflow: hidden

            display: flex
            justify-content: center
            align-items: center
            img
                width: 100%
                height: 100%
                object-fit: cover
            div
                width: 100%
                display: flex
                justify-content: center
                align-items: center
        span
            margin-left: 16px
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 700
            font-size: 18px
            line-height: 28px
            color: #211E1E
            @media (max-width: 640px)
                line-height: 22px
                width: 60%
    &__measurements    
        display: flex
        flex-direction: column
        @media (max-width: 640px)
            margin-top: 16px
        &__title
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 500
            font-size: 14px
            line-height: 28px
            color: #211E1E
            opacity: 0.54
        &__value
            margin-top: -4px
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 500
            font-size: 18px
            line-height: 28px
            color: #211E1E
            @media (max-width: 640px)
                margin-top: -6px

    &__burger-icon
        position: relative
        margin-left: auto
        @media (max-width: 640px)
            display: none
        svg
            cursor: pointer
            transition: opacity .4s ease
            &:hover
                opacity: 0.34

    &__tooltip
        top: 28px
        right: 0
        width: 298px
        min-height: 40px
        max-width: 298px
        overflow: hidden

        transition: opacity .5s ease
        position: absolute
        z-index: 144
        background: white
        box-sizing: border-box
        padding: 8px 16px
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: 500
        font-size: 14px
        line-height: 20px
        color: #211E1E
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.07)
        border-radius: 12px
        @media (max-width: 640px)
            opacity: 1
            position: relative
            border: 1px solid red

    &__description
        display: flex
        flex-direction: column
        margin-top: 8px
        span
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 500
            font-size: 14px
            line-height: 28px
            color: #211E1E
            opacity: 0.5

        div
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 500
            font-size: 14px
            line-height: 20px
            color: #211E1E
            overflow-wrap: anywhere

    &__color-panel
        margin-left: 32px
        box-sizing: border-box
        padding: 8px 16px
        background: #F7E38A
        border-radius: 10px
        font-family: Open Sans, sans-serif
        font-style: normal
        font-weight: 600
        font-size: 16px
        line-height: 20px
        color: #000000
        @media (max-width: 640px)
            margin-left: auto
            margin-top: 24px