.consultant-page-wrapper
    position: relative
    right: 0
    margin-left: auto
    width: 100%
    height: 100%
    display: flex
    flex-direction: column

    box-sizing: border-box
    padding: 48px 40px 120px 40px

    overflow-y: scroll
    .popup_black
        position: fixed
        top: 0
        left: 0
        background: rgba(0,0 ,0 ,0.5 )
        display: flex
        align-items: center
        justify-content: center
        width: 100vw
        height: 100vh
        z-index: 1000
        
        .show__to__buye
            position: static
            transform: none
            div
                align-self: flex-end
                cursor: pointer
    @media (max-width: 640px)
        padding: 24px 26px 140px 22px
        width: 100%
        max-width: 100%
        height: fit-content
    &::-webkit-scrollbar 
        width: 6px
        height: 5px
        margin: 30px
    &::-webkit-scrollbar-track 
        box-shadow: 0
        border-radius: 5px
        margin: 20px
    &::-webkit-scrollbar-thumb 
        background: #219653
        border-radius: 5px
        height: 10%

    &__header
        display: flex
        flex-direction: row
        align-items: center
        position: relative
        margin-bottom: 38px
        @media (max-width: 640px)
            margin-bottom: 84px

        &__photo
            width: 76px
            height: 76px
            border-radius: 50%
            overflow: hidden
            background: #e3e3e3
            display: flex
            justify-content: center
            align-items: center
            @media (max-width: 640px)
                width: 56px
                height: 56px
            img
                width: 100%
                height: 100%
                object-fit: cover
            svg
                width: 100%
        &__column
            margin-left: 24px

            display: flex
            flex-direction: column
            width: fit-content
            max-width: calc(100% - 100px)
            @media (max-width: 640px)
                max-width: calc(100% - 80px)
            &__name
                font-family: 'Montserrat', sans-serif
                font-style: normal
                font-weight: 700
                font-size: 36px
                line-height: 24px
                color: #211E1E
                @media (max-width: 640px)
                    font-size: 24px
                    line-height: 28px
            
            &__status
                margin-top: 24px
                width: fit-content
                box-sizing: border-box
                padding: 6px 16px
                border-radius: 4px

                font-family: 'Roboto', sans-serif
                font-style: normal
                font-weight: 700
                font-size: 14px
                line-height: 16px

                color: #FFFFFF
                @media (max-width: 640px)
                    margin-top: 16px

        .green-button
            position: absolute
            right: 0
            bottom: 0
            margin-bottom: -12px
            width: fit-content
            @media (max-width: 640px)
                margin-bottom: -60px

    &__data-wrapper
        display: flex
        flex-direction: row
        align-items: center
        

        @media (max-width: 640px)
            min-height: 0
            flex-direction: column
            align-items: center

        & > div
            background: white
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07)
            border-radius: 12px
            height: 100%
            @media (max-width: 640px)
                min-height: fit-content
                width: 100%
            .title
                font-family: 'Montserrat', sans-serif
                font-style: normal
                font-weight: 700
                font-size: 18px
                line-height: 28px
                color: #211E1E

        &__rating
            box-sizing: border-box
            padding: 24px
            display: flex
            flex-direction: column
            justify-content: space-between
            align-items: center
            @media (max-width: 640px)
                flex-direction: row
                padding: 12px 28px
                height: fit-content
            & > div
                margin-top: auto
                margin-bottom: 40%
                font-family: 'Roboto', sans-serif
                font-style: normal
                font-weight: 700
                font-size: 60px
                line-height: 70px
                color: #219653
                @media (max-width: 640px)

                    margin-bottom: 0px
        &__desc
            width: 100%
            margin-left: 40px
            box-sizing: border-box
            padding: 24px 40px    
            display: flex
            flex-direction: column
            @media (max-width: 640px)
                margin-left: 0px
                margin-top: 42px
                padding: 24px
            &__text
                margin-top: 18px      
                font-family: 'Montserrat', sans-serif
                font-style: normal
                font-weight: 400
                font-size: 14px
                line-height: 20px
                color: #000000

    &__reviews-wrapper
        display: flex
        flex-direction: column
        margin-top: 36px
        position: relative
        //margin-top: 1000px
        &__title
            font-family: 'Montserrat', sans-serif
            font-style: normal
            font-weight: 700
            font-size: 18px
            line-height: 28px
            color: #211E1E
        &__reviews
            display: flex
            flex-direction: row
            flex-wrap: wrap
            height: fit-content
            @media (max-width: 640px)
                flex-direction: column
            & > div
                width: calc(25% - 21px)
                padding: 16px
              
                margin-top: 16px
                background: #FFFFFF
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07)
                border-radius: 12px
                @media (max-width: 640px)
                    width: 100%
                    box-sizing: border-box

        .white-button
            margin-top: 30px
            width: fit-content
        