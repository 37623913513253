.documents__text
    width: 1000px
    margin: 0 auto
    padding: 40px 80px
    background: #fff
    margin-top: 40px
    box-shadow: 1px 1px 3px 1px #333
    @media (max-width: 640px)
        width: 300px
        margin: 0 auto
        padding: 10px 20px
        background: #fff
        margin-top: 40px
        box-shadow: 1px 1px 3px 1px #333