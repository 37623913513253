.person-list-statistic1
    display: flex
    flex-direction: column
    overflow-y: scroll
    overflow-x: hidden
    width: 100%
    width: 400px
    @media (min-width: 640px)
        width: 400px
        min-width: 400px
        max-height: 720px
        max-width: 30%
        height: 554px
        margin-right: 14px
       
   
    @media (max-width: 1480px)
        min-width: 300px
        max-width: 26%
    &::-webkit-scrollbar 
        width: 4px
        height: 5px
    &::-webkit-scrollbar-track 
        box-shadow: 0
        border-radius: 5px
        margin: 20px 5px
        width: 4px
    &::-webkit-scrollbar-thumb 
        background: #219653
        border-radius: 5px
        width: 4px
        height: 10%
        background-clip: padding-box
    &__title
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: 700
        font-size: 19px
        line-height: 28px
        color: #211E1E
        position: relative
        @media (max-width: 640px)
            margin-top: 10px
    &__panel
        position: relative
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        background: #FFFFFF
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07)
        border-radius: 12px
        box-sizing: border-box
        padding: 0 5px
        margin: 2px 0
        height: 50px
        width: 100%
        max-width: 98%
        border: 2px solid white
        &__index
            font-family: 'JetBrains Mono', monospace
            font-style: normal
            font-weight: 400
            font-size: 16px
            line-height: 28px
            color: #000000
        &__image
            display: flex
            justify-content: center
            align-items: center
            width: 30px
            height: 30px
            max-width: 30px
            max-height: 30px
            border-radius: 50%
            margin: 0 7px

            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 700
            font-size: 20px
            line-height: 28px
            color: #FFFFFF
        &__title
            display: flex
            flex-direction: column
            div
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: 700
                font-size: 16px
                line-height: 28px
                color: #211E1E
            span
                max-width: 270px
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: 400
                font-size: 12px
                line-height: 28px
                height: 28px
                margin-top: -8px
                color: #000000
        &__rating
            display: flex
            flex-direction: row
            align-items: center
            margin-left: auto
            max-width: 60px
            justify-content: flex-end
            width: fit-content
            span, pre
                min-width: 22.5px
                max-width: 22.5px
                margin-left: 5px
                font-family: 'JetBrains Mono', monospace
                font-style: normal
                font-weight: 400
                font-size: 14px
                line-height: 28px
                color: #000000

    .choosen
        border: 2px solid #219653

.person-list-statistic-opener
    position: fixed
    bottom: 0

    height: 30px
    z-index: 2000
    left: calc(50% - 75px)
    border-radius: 12px 12px 0 0
    display: flex
    justify-content: center
    align-items: center
    width: 150px
    background: white
    border: 1px solid #219653

.person-list-statistic-closed
    position: fixed
    width: 100%
    z-index: 100
    bottom: 33px
    height: 100%
    background: #F4F4F4
    transition: max-height 0.6s cubic-bezier(.49,.48,.5,.48), border .4s ease
.person-list-statistic-opened1
   
    height: 100%
    width: 100%
    background: #F4F4F4
    transition: max-height .6s cubic-bezier(.49,.48,.5,.48), border .4s ease
    @media (max-width: 640px)
        z-index: 1000
        position: static
        margin: 0 auto
        background: #fff
.person__modal__name
    position: absolute
    background: #fff
    top: 25px
    left: 52px
    border: 1px solid #219653
    display: none
    flex-direction: column
    font-weight: bold
    font-size: 18px
    padding: 10px
    width: 80%  !important
    pointer-events: none
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07)
    border-radius: 12px
    z-index: 100
    span
        font-weight: normal
      
        font-size: 14px
        height: auto
        margin-top: 0
    b
        margin-right: 5px
.person-list-statistic__panel__title:hover
    .person__modal__name
        display: flex