.person-change-container
    width: 100%
    height: 100%
    display: flex
    position: relative
    width: 100%
    height: calc(100% - 72px)
    max-height: calc(100% - 72px)
    display: flex
    flex-direction: column
    align-items: flex-start
    box-sizing: border-box
    overflow: hidden
    padding-bottom: 10px
    box-sizing: border-box
    z-index: 90
    

    .title
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: 700
        font-size: 28px
        line-height: 30px
        color: #211E1E
        margin-bottom: 25px
    
    .row
        display: flex
        position: relative
        flex-direction: row
        align-items: center
        justify-content: flex-start
        width: fit-content
        margin: 15px 0
        width: 100%
        @media (max-width: 640px)
            display: flex
            flex-direction: column
            align-items: flex-start
            margin: 5px 0

        span
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 500
            font-size: 13px
            line-height: 20px
            color: rgba(33, 30, 30, 0.43)
            width: 107px
        &__input
            // min-width: 100%
            width: 100%
            max-width: 560px
            background: #FFFFFF
            border: 2px solid #E6E6E8
            border-radius: 12px
            box-sizing: border-box
            padding: 6px 16px

            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            
            input
                width: 100%
                outline: none
                border: none
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: 700
                font-size: 18px
                line-height: 28px
                color: #000000
                            

.gender-row
    background: none
    border: none
    display: flex
    flex-direction: row
    align-items: center

.date-input
    width: 100%
    display: flex
    position: relative
    flex-direction: row
    align-items: center
.choose-person-statuses-wrapper__bottom-side
    
    margin-top: 54px
    position: absolute
    left: 0
#choose-person-statuses-wrapper__person
    max-width: 560px
.button-changer 
    display: flex
    position: fixed
    bottom: 0
    padding: 20px 27px
    filter: drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.1))
    width: 100%
    justify-content: flex-end
    right: 0
    background: white
    @media (max-width: 640px)
        
        justify-content: center
        padding: 20px 0
        a
            color: green
    .green-button
        width: fit-content
        margin-left: 19px
.date-icon
    position: absolute
    right: 0
.red
    border: 1px solid #E3655D !important
.person-edit-modal__form-wrapper
    .choose-person-statuses-wrapper__bottom-side
        left: 0
.adaptive-choose-person-wrapper__modal-wrapper
    .desc
        width: 100% !important
        color: black !important
    .title
        width: 100% !important
        margin: 0
        color: black !important
        margin-top: 15px