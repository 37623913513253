.choose-consultant-panel-wrapper
    display: flex
    flex-direction: row
    align-items: center
    background: #FFFFFF
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07)
    border-radius: 12px
    box-sizing: border-box
    padding: 16px 24px
    margin-top: 16px
    position: relative

    @media ( max-width: 640px )
        flex-direction: column
        align-items: flex-start
        padding: 26px 16px 16px 16px

    &__title
        display: flex
        flex-direction: row
        align-items: center
        max-width: 40%
        width: 40%
        cursor: pointer
        @media ( max-width: 640px ) 
            width: fit-content
            max-width: 100%

        &__photo
            position: relative
            height: 44px
            width: 44px
            border-radius: 44px
            background: rgba(0, 0, 0, 0.07)
            overflow: hidden
            img
                width: 100%
                height: 100%
        &__name
            margin-left: 16px
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 18px
            line-height: 28px
            color: #211E1E

    &__info
        display: flex
        flex-direction: row
        align-items: center

        &__workloadDisplay
            box-sizing: border-box
            padding: 6px 16px
            border-radius: 4px
            font-family: Roboto, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 14px
            line-height: 16px
            text-align: center
            width: 94px
            color: #FFFFFF  

            @media ( max-width: 640px ) 
                margin-top: 16px

        &__column
            display: flex
            flex-direction: column
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 500
            font-size: 18px
            line-height: 28px
            color: #211E1E
            margin-left: 48px
            @media ( max-width: 640px ) 
                margin-left: 0px
                margin-right: 40px
            span
                font-size: 14px
                line-height: 24px
                opacity: 0.54
                margin-top: 4px
            div
                height: 28px
                position: relative
                margin-bottom: 4px
    
    &__choose-wrapper
        position: absolute
        right: 16px

        @media ( max-width: 640px )
            position: absolute
            right: 16px
            bottom: 16px