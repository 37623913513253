.rating-panel-wrapper
    width: 100%
    height: fit-content
    display: flex
    flex-direction: row
    position: relative
    z-index: 1
    margin: 3vh 40px 2px 40px 

    @media (max-width: 640px)
        flex-direction: column
        overflow-x: none
        margin: 0 auto
        width: 100vw
        max-width: 100vw
        padding-bottom: 14px
    .rating-panel
        align-self: flex-end
        position: relative
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        border: 4px solid #F4F4F4
        bottom: 0
        height: 47vh
        border-radius: 20px
        width: calc(100%/7)
        margin-right: 10px
        cursor: pointer
        transition: all .5s ease
        img
            //transform: scale(0.8)
            width: 98%
        @media (max-width: 640px)
            height: 40vh
            width: 70vw
            min-width: 70vw
            margin: 0 15vw
            box-sizing: border-box
            padding:5px
            img
                width: 65%
                

        &__text
            display: flex
            flex-direction: column
            align-items: center
            margin: 0 auto
            &__1
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: bold
                font-size: 14px
                line-height: 24px
                color: #FFFFFF
            &__2
                display: flex
                justify-content: center
                align-items: center
                box-sizing: border-box
                padding: 2px 20px

                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: bold
                font-size: 16px
                line-height: 24px
                color: #729932
                background: #FFF
                border-radius: 20px
                margin: 4px

    .choosen-rating-panel
        height: 50vh
        border: 4px solid #000000

    &__confirm-button
        margin: 17px auto
        display: flex
        justify-content: center
        align-items: center
        background: #53CF5C
        border-radius: 20px
        border: none

        box-sizing: border-box
        padding: 11px 33px

        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: bold
        font-size: 14px
        line-height: 17px
        color: #FFFFFF

.choosing-line
    background: linear-gradient(90deg, #D76367 0%, #EDDB7C 51.56%, #66D06B 96.88%)
    border-radius: 10px
    height: 22px
    width: 100%
    max-width: 325px
    margin: 0 auto
    margin-top: 24px
    display: flex
    flex-direction: row
    position: relative
    display: flex
    justify-content: center
    align-items: center
    z-index: 994
    &__panel
        width: 100%
        display: flex
        justify-content: center
        align-items: center
        position: relative
        div
            width: 10px
            height: 10px
            border-radius: 50%

#dragging-icon
    position: absolute
    z-index: 997
    margin: 0 auto
    pointer-events: none
.rating-panel:hover
    @media (min-width: 640px)
        width: 25%
        height: 60vh
        transform-origin: center
.rating_car
    display: flex
    width: 100vw
    overflow-x: hidden
.rate_train
    display: flex
    transition: 0.5s