.commentary-measurement-person-list-wrapper
    display: flex
    flex-direction: column
    width: 100%
    div
        display: flex
        background: #FFFFFF
        padding: 10px 20px
        box-sizing: border-box
        border-radius: 20px 
        width: 804px
        @media (max-width: 640px)
            width: 100%
        input
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: normal
            font-size: 14px
            line-height: 17px
            color: #211E1E
            width: 100%
            outline: none
            border: none
            &::placeholder
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: normal
                font-size: 14px
                line-height: 17px
                color: rgba(33, 30, 30, 0.5)


    span
        width: 538px
        margin-top: 21px
        
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: bold
        font-size: 12px
        line-height: 15px

        color: #219653
        @media (max-width: 640px)
            display: none