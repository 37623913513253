.account-change-password-wrapper
    position: relative
    margin: 110px 25px 0
    &__closed-panel
        display: flex
        flex-direction: row
        align-items: center
        width: 100%
        position: relative
        justify-content: space-between
        @media (max-width: 640px)
            justify-content: space-between
            max-width: 100%
            margin: 38px 24px 0 24px
        &__title
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: normal
            font-size: 14px
            line-height: 17px
            color: #211E1E
            opacity: 0.5
            width: calc(33% - 14px)
        &__description
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: normal
            font-size: 16px
            line-height: 20px
            color: #211E1E

            width: calc(33% - 14px)
            @media (max-width: 640px)
                position: absolute
                margin-top: 36px
                right: 50px
        &__button
            width: calc(33% - 14px)
            font-family: Open Sans, sans-serif
            font-style: normal
            font-weight: 600
            font-size: 16px
            line-height: 20px
            text-decoration-line: underline
            color: #219653
            cursor: pointer
            @media (max-width: 640px)
                margin-left: auto
                margin-right: 50px

    &__form-wrapper
        display: flex
        flex-direction: column
        width: 100%
        max-width: 100%

        margin: 80px 25px 0 25px
        position: relative
        @media (max-width: 640px)
            width: calc(100% - 48px)
            margin: 38px 24px 0 24px
            padding-bottom: 120px
        &__row
            display: flex
            flex-direction: row
            align-items: center
            width: 100%
            max-width: 100%
            position: relative
            margin-bottom: 18px
            @media (max-width: 640px)
                display: flex
                flex-direction: column
                align-items: flex-start
                margin-top: 11px
            & > span
                width: calc(33% - 7px)
                max-width: 33%
                
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: normal
                font-size: 14px
                line-height: 17px
                color: #211E1E
                opacity: 0.5
                @media (max-width: 640px)
                    width: 100%
                    max-width: 100%
            
            &__error
                position: absolute
                right: 72px
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: normal
                font-size: 18px
                line-height: 21px
                color: #E3655D

            & > .text-input
                width: calc(33% - 28px)
                @media (max-width: 640px)
                    width: 100%
                    margin-top: 6px
                input
                    min-width: 100%
            
            &__buttons-panel
                display: flex
                flex-direction: row
                right: 50px
                position: absolute
                @media (max-width: 640px)
                    right: 0
                    position: relative
                    margin: 22px auto
                .green-button
                    margin-left: 18px

