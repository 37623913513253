.payment_style
    margin-left: 280px
    @media (max-width: 640px)
        margin-left: 20px
    h1
        font-size: 36px
    div
        display: flex
        align-items: center
        span
            font-weight: normal
            font-size: 20px
        p
            font-weight: bold
            margin-right: 5px
            font-size: 20px
    .green-button
        width: fit-content
.get_cons
    flex-direction: column
    align-items: flex-start !important