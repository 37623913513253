.my-account-wrapper
    
    &__social-networks
        position: relative
        width: 100%
        right: 0
        margin: 0 25px

        display: flex
        flex-direction: column

        span
            margin: 24px 0 8px 0
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: normal
            font-size: 14px
            line-height: 17px

            color: #211E1E

            opacity: 0.5