.text_format
    padding: 20px
    padding-left: 40px
    padding-right: 200px
    font-size: 16px
    font-family: Montserrat, sans-serif
    @media screen and ( max-width: 640px )
        padding: 20px
        padding-left: 20px
        padding-right: 20px
        font-size: 14px
.instruction-measurement-container
    @media screen and ( max-width: 640px )
        padding-bottom: 150px