.add-measurement-to-consultation-modal-wrapper
    position: fixed
    left: 0
    top: 0
    z-index: 999
    width: 100vw
    height: 100vh
    background: rgba(1,1,1,0.3)

    display: flex
    justify-content: center
    align-items: center
    

    .add-measurement-to-consultation-modal
    
        display: flex
        flex-direction: column
        border-radius: 12px
        @media (max-width: 640px)
            width: 96vw
        &__title
            border-radius: 12px 12px 0 0
            width: 100%
            background: #FFFFFF
            box-sizing: border-box
            padding: 24px

            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 16px
            line-height: 24px
            letter-spacing: 1px
            text-transform: uppercase
            color: #211E1E  
            
        &__control-panel
            border-radius: 0 0 12px 12px
            width: 100%
            background: #FFFFFF
            box-sizing: border-box
            padding: 20px 32px
            display: flex
            flex-direction: row
            align-items: center
            filter: drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.1))
            @media (max-width: 640px)
                justify-content: space-between
            .green-button
                width: fit-content
                margin-right: 24px
        &__main-section
            background: #F4F4F4
            max-height: 490px
            min-height: 100%
            height: 100%
            width: 100%
            display: flex
            flex-direction: column

            box-sizing: border-box
            padding: 20px 32px

            overflow-y: scroll
            overflow-x: hidden

            @media (max-width: 640px)
                padding: 12px 8px
                max-height: 330px
            &::-webkit-scrollbar 
                width: 6px
                height: 5px
                margin: 30px
            &::-webkit-scrollbar-track 
                box-shadow: 0
                border-radius: 5px
                margin: 20px
            &::-webkit-scrollbar-thumb 
                background: #219653
                border-radius: 5px
                height: 10%

            &__title
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: bold
                font-size: 16px
                line-height: 28px
                color: #211E1E

            &__measurement-list
                display: flex
                flex-direction: column
                align-items: center

            .measurement-modal-panel
                width: 100%
                margin-top: 16px
                background: #FFFFFF
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07)
                border-radius: 12px
                box-sizing: border-box
                padding: 12px 32px 

                display: flex
                flex-direction: row
                align-items: center
                justify-content: space-between
                
                @media (max-width: 640px)
                    padding: 8px

                &__left-side
                    display: flex
                    flex-direction: row
                    align-items: center

                    &__title
                        margin-left: 24px
                        text-overflow: ellipsis
                        font-family: Montserrat, sans-serif
                        font-style: normal
                        font-weight: bold
                        font-size: 18px
                        line-height: 28px
                        width: 200px
                        color: #211E1E
                        @media (max-width: 640px)
                            display: none
                    
                    svg
                        width: 24px
                        height: 24px

                &__desc
                    justify-self: flex-end
                    display: flex
                    justify-content: space-between
                    flex-direction: row
                    width: 100%
                    @media (max-width: 640px)
                        flex-direction: column
                        margin-left: 5px
                    div
                        display: flex
                        flex-direction: column
                        
                        text-overflow: ellipsis
                        overflow: hidden
                        white-space: nowrap

                        font-family: Montserrat, sans-serif
                        font-style: normal
                        font-weight: 500
                        font-size: 18px
                        line-height: 28px   
                        color: #211E1E

                        margin-right: 32px
                        @media (max-width: 640px)
                            white-space: normal
                            width: 100%
                            margin-right: 0
                            line-height: 24px
                            font-size: 16px
                        span
                            opacity: 0.54
                            font-size: 14px

