.person-edit-modal-wrapper
    position: fixed
    left: 0
    top: 0
    z-index: 999
    width: 100vw
    height: 100vh
    background: rgba(1,1,1,0.3)

    display: flex
    justify-content: center
    align-items: center

    .person-edit-modal
        display: flex
        flex-direction: column
        box-sizing: border-box
        padding: 24px 32px 
        background: #FFFFFF
        border-radius: 12px
        width: 53vw
        @media (max-width: 640px)
            width: calc(100vw - 24px)
            padding: 14px 10px
        &__title
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 28px
            line-height: 30px
            color: #211E1E

        &__form-wrapper
            display: flex
            flex-direction: column
            margin-top: 17px

            &__row
                display: flex
                flex-direction: column
                margin-top: 12px
                span
                    font-family: Montserrat, sans-serif
                    font-style: normal
                    font-weight: 500
                    font-size: 13px
                    line-height: 20px
                    color: #211E1E

                input
                    background: #FFFFFF
                    padding: 6px 16px
                    border: 2px solid #E6E6E8
                    box-sizing: border-box
                    border-radius: 12px
                    max-width: 366px
                    text-overflow: ellipsis
                    font-family: Montserrat, sans-serif
                    font-style: normal
                    font-weight: bold
                    font-size: 18px
                    line-height: 28px
                    color: #000000

                    &::focus
                        border: none
                    &::active
                        border: none

            .modal-role-wrapper
                display: flex
                flex-direction: row
                margin-bottom: 185px
                @media (max-width: 640px)
                    flex-direction: column
                    margin-bottom: 0
                    //margin-bottom: 0
                span
                    
                    margin-right: 64px
                    @media (max-width: 640px)
                        margin-right: 0
                .adaptive-choose-person-wrapper__input-wrapper
                    border: 1px solid rgba(0, 0, 0, 0.3)

            .modal-gender-wrapper
                display: flex 
                flex-direction: row
            .choose-gender-measurement-person-wrapper__gender-panel
                //border: 1px solid rgba(0, 0, 0, 0.3)
            &__button-wraper
                display: flex
                flex-direction: row
                align-items: center
                justify-content: space-between
                @media (max-width: 640px)
                    margin-top: 15px
                .green-button
                    max-width: 246px
                    @media (max-width: 640px)
                        max-width: 157px
                .white-button
                    box-sizing: border-box
                    padding-left: 74px
                    padding-right: 74px
                    @media (max-width: 640px)
                        padding-left: 30px
                        padding-right: 30px
                    
