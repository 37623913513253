.my-service-panel
    display: flex
    width: 100%
    flex-direction: row
    align-items: center
    justify-content: center
    height: 76px
    margin: 8px 0
    background: #FFFFFF
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07)
    border-radius: 12px
    box-sizing: border-box
    padding: 10px 20px
    @media (max-width: 640px)
        flex-direction: column
        height: fit-content
        padding: 16px

    &__title
        max-width: 302px
        width: 100%
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: bold
        font-size: 18px
        line-height: 28px
        max-height: 56px

        overflow: hidden
        text-overflow: ellipsis
        @media (max-width: 640px)
            max-height: 84px
            text-align: start
            margin-right: auto
            margin-top: 11px

    &__amount_date-wrapper
        display: flex
        flex-direction: row
        align-items: center
        width: 100%
        max-width: 400px
        @media (max-width: 640px)
            margin-top: 8px
            justify-content: space-between
        & > div
            display: flex
            flex-direction: column
            margin-right: 72px
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 500
            font-size: 14px
            line-height: 28px
            
            max-width: 150px
            width: fit-content
            & > div
                opacity: 0.54
            @media (max-width: 640px)
                margin: 0
            & > span
                font-size: 18px
                opacity: 1
                color: #211E1E
                @media (max-width: 640px)
                    margin-top: -8px
    
    &__period
        display: flex
        flex-direction: row
        align-items: center
        margin-right: auto
        @media (max-width: 640px)
            margin-top: 4px
        & > div
            display: flex
            flex-direction: column
            margin-right: 7%
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 500
            font-size: 14px
            line-height: 28px
            width: 147px
            & > div
                opacity: 0.54
                line-height: 18px
                margin-bottom: 10px
            & > span
                font-size: 18px
                opacity: 1
                @media (max-width: 640px)
                    margin-top: -8px