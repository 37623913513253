.header-outer
    position: relative
    width: 100vw
    height: 72px
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    @media (max-width: 640px)
        height: 56px
        max-width: 100vw
        overflow: hidden
.header-inner
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center

    margin: 0px 40px 0px 32px
    transition: margin 0.6s ease
    width: 100%
    @media (max-width: 640px)
        margin: 0 auto
        width: 100vw
        padding: 0 20px
        box-sizing: border-box

.shorter-header-inner
    margin: 0px 40px 0px 260px
    transition: margin 0.6s ease
    @media (max-width: 640px)
        margin: 0 auto
.header-logo
    display: flex
    flex-direction: row
    align-items: center
.header-logo
    img
        width: 56px
.header-right-side
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    box-sizing: border-box
    margin-left: auto
    @media (max-width: 640px) 
        margin: 0 auto
    div:not(.header-right-side__menu-fold)
        display: flex
        align-items: center
        justify-content: center
        text-align: center

    &__reg-button
        padding: 10px 16px

    &__login-button
        margin-left: 24px
        padding: 10px 16px

    &__menu-fold
        margin-left: 24px
        cursor: pointer
        display: none
        @media (max-width: 640px) 
            display: flex

    &__info-circle

        margin-left: 24px
        cursor: pointer
        @media (max-width: 640px)
            margin-left: 0
            position: absolute
            right: 20px
.black-article-header
    position: absolute
    left: 0
    overflow: hidden
    width: 0%
    height: 56px
    background: #211E1E
    box-sizing: border-box
    display: flex
    flex-direction: row
    align-items: center
    transition: all .4s ease
    svg
        margin-right: 24px

.opened-black-header
    padding: 16px 24px
    width: 100%

.header-titles-wrapper
    display: flex
    flex-direction: row
    align-items: center
    
    font-family: Montserrat, sans-serif
    font-style: normal
    font-weight: bold
    font-size: 16px
    line-height: 24px
    letter-spacing: 1px
    text-transform: uppercase
    color: #211E1E

    box-sizing: border-box
    padding-left: 36px

    &__additional-title
        margin-left: 86px


.header-stepbar-wrapper
    margin: 0 auto