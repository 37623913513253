.notifications__messages
    margin-left: 280px
    margin-top: 15px
    height: calc(100vh - 146px)
    overflow-y: scroll
    @media (max-width: 640px)
        margin-left: 0
.not_elem
    width: 700px
    background: white
    display: flex
    justify-content: space-between
    flex-direction: row
    align-items: center
    box-shadow: 0px 2px 4px rgb(0 0 0 / 7%)
    border-radius: 12px
    box-sizing: border-box
    padding:  24px
    margin: 7px 0
    cursor: pointer
    @media (max-width: 640px)
        width: 94%
        margin: 8px auto
    span
       
        font-size: 18px
        margin-right: 20px
    .elem__date
        color: gray
        font-weight: normal
.notifications__none
    font-size: 20px
    font-weight: bold
    @media (max-width: 640px)
        margin-left: 15px
.buttons__notice
    display: flex
    justify-content: space-between
    margin-left: 280px
    margin-top: 20px
    width: 700px
    @media (max-width: 640px)
        flex-direction: column
        width: 100%
        margin-left: 10px
        justify-content: flex-start
        align-items: flex-start
    .white-button
        @media (max-width: 640px)
            margin-bottom: 14px