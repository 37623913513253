.consultant-modal-wrapper
    position: fixed
    top: 0
    left: 0
    z-index: 600
    width: 100vw
    height: 100vh
    background: rgba(0,0,0,0.6)
    display: flex
    justify-content: center
    align-items: center
    z-index: 2000

    .consultant-modal
        position: fixed
        width: 69vw
        z-index: 2000
        background: #F3F3F3
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
        border-radius: 12px

        box-sizing: border-box
        padding: 11px 74px 28px 36px

        display: flex
        flex-direction: column
        @media ( max-width: 640px )
            padding: 0
            width: 94%
            padding-bottom: 15px
        &__cross
            position: absolute
            right: 25px
            top: 17px
            cursor: pointer
            transition: all .4s ease
            @media ( max-width: 640px )
                z-index: 100
                right: 17px
                top: 15px
            svg
                @media ( max-width: 640px )
                    width: 20px
                    height: 20px
                    
            &:hover
                transform: scale(1.2)

        &__top-panel
            display: flex
            flex-direction: row
            align-items: center
            position: relative
            width: 100%
            @media ( max-width: 640px )
                margin-top: 45px
                padding: 0 15px
                box-sizing: border-box
                margin-bottom: 10px
            &__image
                height: 86px
                width: 86px
                border-radius: 86px
                background: rgba(0, 0, 0, 0.25)
                overflow: hidden
                @media ( max-width: 640px )
                    display: none
                img 
                    width: 100%
                    height: 100%
                    object-fit: contain
            
            &__name
                display: flex
                flex-direction: column
                margin-left: 28px
                @media ( max-width: 640px )
                    margin-left: 0
                span
                    font-family: Montserrat, sans-serif
                    font-style: normal
                    font-weight: bold
                    font-size: 36px
                    line-height: 24px
                    color: #211E1E
                    @media ( max-width: 640px )
                        line-height: 30px
                        font-size: 30px
                div
                    width: fit-content
                    margin-top: 24px
                    box-sizing: border-box
                    padding: 6px 16px
                    border-radius: 4px
                    font-family: Roboto, sans-serif
                    font-style: normal
                    font-weight: bold
                    font-size: 14px
                    line-height: 16px

                    color: #FFFFFF

            &__rating
                margin-left: auto
                box-sizing: border-box
                padding: 11px 26px 8px 26px
                background: #FFFFFF
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07)
                border-radius: 12px
                display: flex
                flex-direction: column
                align-items: center
                
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: bold
                font-size: 18px
                line-height: 28px
                color: #211E1E

                span
                    height: 70px
                    font-family: Roboto, sans-serif
                    font-style: normal
                    font-weight: bold
                    font-size: 60px
                    line-height: 70px

                    color: #219653

        &__bottom-panel
            display: flex
            flex-direction: row
            
            margin-top: 25px
            
            position: relative
            @media ( max-width: 640px )
                flex-direction: column
            &__reviews-count
                position: absolute
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: bold
                font-size: 18px
                line-height: 28px
                color: #211E1E

                left: calc(33% + 30px)
                bottom: 100%
            &__description
                background: #FFFFFF
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07)
                border-radius: 12px
            
                width: 33%
                display: flex
                flex-direction: column

                box-sizing: border-box
                padding: 14px

                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: normal
                font-size: 14px
                line-height: 20px
                color: #000000

                overflow: hidden
                @media ( max-width: 640px )
                    width: 90%
                    margin: 0 auto
                span
                    font-weight: bold
                    font-size: 18px
                    line-height: 28px
                    color: #211E1E
                div
                    max-height: calc(100% - 9px)
                    text-overflow: ellipsis
            &__review
                margin-left: 30px
                width: 33%
                height: calc(100% - 8px)
                max-height: calc(100% - 8px)
                background: #FFFFFF
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07)
                border-radius: 12px

                display: flex
                flex-direction: column
                align-items: center
                box-sizing: border-box
                padding: 20px
                @media ( max-width: 640px )
                    width: 90%
                    margin: 10px auto
                span
                   
                    text-overflow: ellipsis

                .white-button
                    margin-top: 12px
                    width: 100%
                    text-align: center
                    
                    display: flex
                    justify-content: center
                    align-items: center

                &__stars-wrapper
                    display: flex
                    flex-direction: row
                    justify-content: space-between
                    width: 100%
                    background: white
                    position: relative
                    margin-bottom: 14px
                    & > div
                        position: absolute
                        z-index: 100
                    // svg
                    //     background-image: linear-gradient(90deg, red 50%, white 50%)
                    //     overflow: hidden

                        


