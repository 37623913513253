.my-account-navbar-wrapper
    display: flex
    flex-direction: column

    position: relative
    right: 0
    width: 100%

    padding: 40px 25px
    box-sizing: border-box
   
    @media (max-width: 640px)
        padding: 40px 12px
        flex-shrink: 0
    &__title
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: bold
        font-size: 36px
        line-height: 24px
        color: #211E1E

    &__navbar
        display: flex
        flex-direction: row
        align-items: center
        width: 100%

        position: relative

        margin-top: 24px
        
        @media (max-width: 640px)
            width: calc(100% - 84px)
            margin: 24px auto 0 auto
            overflow-x: scroll
        &::-webkit-scrollbar 
            width: 0px
            background: transparent
            opacity: 0
        &::-webkit-scrollbar-track 
            box-shadow: 0
        &::-webkit-scrollbar-thumb 
            background: rgba(0,0,0,0)
            border-radius: 5px
            height: 10%
        &__line
            margin-top: 2px
            width: 100%
            height: 2px
            background: #FFFFFF
            position: absolute
            bottom: 0
            z-index: 1 
            overflow-x: hidden
            @media (max-width: 640px)
                width: 135vw
                margin-bottom: 4px
        &__nav-panel
            margin: 0 16px
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 18px
            line-height: 28px
            color: #211E1E
            flex-shrink: 0
            position: relative
            width: fit-content
            scroll-behavior: smooth
            @media (max-width: 640px)
                margin: 3px 5px
                line-height: 17px
                text-align: center
                height: 36px
                padding-bottom: 2px
                display: flex
                margin-bottom: 4px
                align-items: center
                a
                    align-items: center
                    display: flex
                    justify-content: center
                    //margin: auto 0

            a
                color: #211E1E
                opacity: 0.43
            &__choosen-line
                position: relative
                z-index: 450
                width: 100%
                height: 4px
                background: #219653
                border-radius: 2px 2px 0px 0px
                @media (max-width: 640px)
                    position: absolute
                    width: 100%
                    bottom: 0px
                    margin-top: 8px
        &:first-child
            margin-left: 0px
            @media (max-width: 640px)
                margin-left: 32px
        .current-nav-panel  
            a
                opacity: 1
                color: #211E1E

        &__left-button
            position: absolute
            z-index: 451
            width: 40px
            height: 40px
            border-radius: 50%
            background: white
            bottom: 60px
            margin-right: 4px
            left: 8px
            box-shadow: 0px 5px 9px -4px rgba(34, 60, 80, 0.08)
            & > div
                width: 100%
                height: 100%
                display: flex
                justify-content: center
                align-items: center
                transition: opacity .4s ease
        &__right-button
            position: absolute
            z-index: 451
            width: 40px
            height: 40px
            border-radius: 50%
            background: white
            bottom: 60px
            right: 8px
            box-shadow: 0px 5px 9px -4px rgba(34, 60, 80, 0.08)
            & > div
                width: 100%
                height: 100%
                display: flex
                justify-content: center
                align-items: center
                transition: opacity .4s ease
                transform: rotate(180deg)