.find-person-input-wrapper
    position: relative
    @media screen and ( max-width: 640px )
        width: 100%
        box-sizing: border-box
    &__input-wrapper
        display: flex
        background: #FFFFFF
        padding: 10px 20px 5px 20px
        box-sizing: border-box
        border-radius: 20px 
        width: 583px
        margin-left: 6px
        @media screen and ( max-width: 640px )
            width: 100%
        input
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: normal
            font-size: 14px
            line-height: 17px
            color: #211E1E
            width: 100%
            outline: none
            border: none
            padding-bottom: 5px
            border-bottom: 1px solid rgba(0, 0, 0, 0.2)
            &::placeholder
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: normal
                font-size: 14px
                line-height: 17px
                color: rgba(33, 30, 30, 0.5)

    .input-with-opened-modal-panel
        border-radius: 20px 20px 0 0
    &__finded-persons-panel
        position: absolute
        top: 40px
        left: 0
        background: #FFFFFF
        width: 100%
        max-width: 538px
        height: 0px
        max-height: 240px
        transition: height 1s ease
        display: flex
        flex-direction: column

        overflow: hidden
        z-index: 991
        div
            display: flex
            flex-direction: column
            margin: 0 24px
            overflow-y: scroll

            &::-webkit-scrollbar 
                width: 6px
                height: 5px
                margin: 30px
            &::-webkit-scrollbar-track 
                box-shadow: 0
                border-radius: 5px
                margin: 20px
            &::-webkit-scrollbar-thumb 
                background: #219653
                border-radius: 5px
                height: 10%
            span
                padding: 5px 8px
                box-sizing: border-box
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: normal
                font-size: 18px
                line-height: 24px
                color: #219653
                cursor: pointer
                border-radius: 6px
                &:hover
                    background: #faf5f5

            .choosen-person-panel
                background: #faf5f5
        @media screen and ( max-width: 640px )
            width: 100%

        &__prompt
            position: relative
            z-index: 900
            height: 32px
            min-height: 32px
            background: #FFFFFF
            padding-bottom: 8px
            margin-right: auto
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: normal
            font-size: 12px
            line-height: 15px
            overflow: none
            color: rgba(33, 30, 30, 0.5)
            @media screen and ( max-width: 640px )
                height: auto
                background: none
                width: 96%
                margin-left: 5px

    .find-persons-opened-panel
        height: fit-content

.input-clean-delete-cross-icon
    position: absolute
    right: 16px
    cursor: pointer