.consultation-chat-wrapper
    position: relative
    right: 0
    bottom: 0
    margin-left: auto
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    overflow: hidden

    &__title-wrapper
        display: flex
        flex-direction: column
        margin: 28px 24px 0 24px
        & > div
            display: flex
            flex-direction: row
            align-items: center
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 700
            font-size: 36px
            line-height: 24px
            color: #000000
            @media (max-width: 640px)
                font-size: 24px
                line-height: 28px
                
        & > span
            margin-top: 30px
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 700
            font-size: 36px
            line-height: 24px
            color: #211E1E
            @media (max-width: 640px)
                display: none
        &__measurements-title
            display: flex
            flex-direction: row
            align-items: center
            margin-left: 24px
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 700
            font-size: 18px
            line-height: 28px
            color: #211E1E
            svg
                margin-right: 8px
    &__measurements-wrapper
        margin: 24px

        display: flex
        flex-direction: row
        align-items: center
        flex-wrap: wrap
        border-bottom: 2px solid white
        //overflow: hidden
        transition: height .4s ease
        @media (max-width: 640px)
            margin: 8px 12px
            padding-bottom: 4px
            flex-wrap: wrap
            z-index: 100
            background: #F4F4F4
            box-shadow: 0px 14px 6px -4px rgba(34, 60, 80, 0.08)
        &__panel
            display: flex
            flex-direction: row
            align-items: center
           
            box-sizing: border-box

            
            cursor: pointer
            @media (max-width: 640px)
                overflow: hidden
            span
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: 700
                font-size: 18px
                line-height: 28px
                color: #211E1E
            svg
                transform: scale(0.8)
                cursor: pointer
                margin-left: 8px
                margin-top: 2px
                path
                    transition: all .4s ease
            &:hover
                path
                    opacity: 0.4
        &__add-measurement
            position: relative
            //width: 100%
        
            display: flex
            flex-direction: row
            align-items: center
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 700
            font-size: 18px
            line-height: 28px
            color: #219653
            cursor: pointer
            padding: 0px 32px 0px 0px
            transition: all .4s ease
            @media (max-width: 640px)
                width: fit-content
            svg
                transform: scale(0.8)
                path 
                    transition: all .4s ease
            &:hover
                color: #208f50
                svg
                    path
                        stroke: #208f50

    &__messages-wrapper
        display: flex
        flex-direction: column-reverse
        margin: 0 24px 88px 24px
        height: 100%
        bottom: 0
        padding-top: 20px
        position: relative
        width: 100%
        overflow-y: scroll
        @media (max-width: 640px)
            position: relative
            max-height: 35%
            margin: 0 24px 88px 0
            //bottom: 130px
        &::-webkit-scrollbar
            width: 0px
            background: transparent
        &__message
            display: flex
            flex-direction: row
            margin-bottom: 16px
            margin-left: 10px
            &__image
                width: 44px
                height: 44px
                border-radius: 50%
                margin-right: 16px
                background: rgba(0, 0, 0, 0.07)
                overflow: hidden

                display: flex
                justify-content: center
                align-items: center
                @media (max-width: 640px)
                    height: 40px
                    width: 40px
                img
                    width: 100%
                    height: 100%
                    object-fit: cover
            &__text
                width: fit-content
                max-width: 550px
                background: #FFFFFF
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07)
                border-radius: 0px 24px 24px 24px
                box-sizing: border-box
                padding: 16px 24px
                padding-bottom: 10px
                display: flex
                flex-direction: column
                @media (max-width: 640px)
                    width: calc(100% - 84px)
                span
                    font-family: Montserrat, sans-serif
                    font-style: normal
                    font-weight: 700
                    font-size: 15px
                    line-height: 20px
                    color: #211E1E
                    margin-bottom: 8px
                &__invoice
                    margin: 8px 0 4px 0
                    width: fit-content
                    box-sizing: border-box
                    padding: 8px 16px
                    background: #F7E38A
                    border-radius: 10px
                    font-family: Open Sans, sans-serif
                    font-style: normal
                    font-weight: 600
                    font-size: 16px
                    line-height: 20px
                    color: #000000
                &__link
                    a
                        color: #219653
                        font-family: Open Sans, sans-serif
                        font-style: normal
                        font-weight: 600
                        font-size: 16px
                        line-height: 20px
                        text-decoration: underline
                        cursor: pointer
                .cancelled-payment-message
                    text-decoration: none
                    color: black
                    text-decoration: line-through
                    text-decoration-color: #219653
                    color: #219653
                    text-decoration-thickness: 2px

                    font-family: Open Sans, sans-serif
                    font-style: normal
                    font-weight: 400
                    font-size: 16px
                    line-height: 20px
                    a
                        text-decoration: none
                .last-paid-bill
                    color: #219653
                    font-family: Open Sans, sans-serif
                    font-style: normal
                    font-weight: 600
                    font-size: 16px
                    line-height: 20px
    .messages-wrapper-short
        @media (max-width: 640px)
            margin-top: auto
            height: 55%
            bottom: 130px
        
    &__bottom-state
        width: 100%
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        position: relative
        bottom: 84px
        z-index: 900
        padding: 27px 98px 27px 90px
        box-sizing: border-box

        @media (max-width: 640px)
            flex-direction: column
            padding: 0
            padding-top: 4px
            bottom: 145px
            padding-bottom: 4px
            position: fixed
            background-color:  #F4F4F4
        &__title
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 700
            font-size: 18px
            line-height: 20px
            color: #000000
        &__desc
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 400
            font-size: 26px
            line-height: 20px
            color: #000000
            
            @media (max-width: 640px)
                margin-top: 1px
                margin-left: auto
                margin-right: 20px
                font-size: 20px
.delete_measurement
    display: flex
    align-items: center
    :hover
        cursor: pointer
.measInfo
    padding: 10px
    border-radius: 15px
    background: white
    position: absolute
    top: 20px
    width: 300px
    left: 0
    z-index: 1000
    display: none
    span
        font-size: 14px
        font-weight: normal
    div
        display: flex
        flex-direction: column
.consultation-chat-wrapper__measurements-wrapper__panel:hover
  
    .measInfo
        display: block
        color: green