.article-page-wrapper
    display: flex
    flex-direction: row
    width: 100%
    position: absolute
    right: 0
    height: 100%
    padding: 43px 32px 32px 24px
    box-sizing: border-box
    overflow-y: hidden

    @media ( max-width: 640px )
        padding: 40px 24px 32px 24px
        width: 100%
        max-width: 100%
        overflow-y: scroll
        
.articles-navigation-sidebar
    position: absolute
    left: 0
    top: 0
    z-index: 996
    height: 100%
    background: #F4F4F4
    max-width: 0%
    overflow: hidden
    transition: max-width .2s ease
    display: inline-flex
    white-space: nowrap
    box-sizing: content-box
    @media ( max-width: 640px )
        position: fixed
        top: 55.5px
    .articles-navigataion-wrapper
        box-sizing: border-box
        margin: 10px
        &__articles-list-wrapper
            & > div
                span
                    white-space: nowrap
                    transition: white-space .1s ease

.articles-navigation-sidebar-opened
    max-width: calc(100vw - 24px)
    transition: max-width .2s ease
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.05), 4px 4px 4px rgba(0, 0, 0, 0.02)
    box-sizing: border-box
    .articles-navigataion-wrapper
        &__articles-list-wrapper
            & > div
                span
                    white-space: normal
                    transition: white-space .1s ease