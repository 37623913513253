.measurement-list-search-wrapper
    display: flex
    flex-direction: row
    align-items: center
    width: 100%
    margin-bottom: 16px
    transition: height .4s ease 
    .popup_black
        position: fixed
        top: 0
        left: 0
        background: rgba(0,0 ,0 ,0.5 )
        display: flex
        align-items: center
        justify-content: center
        width: 100vw
        height: 100vh
        z-index: 1000
        
        .show__to__buye
            position: static
            transform: none
            div
                align-self: flex-end
                cursor: pointer
    &__type-input
        position: relative

        display: flex
        flex-direction: column
        align-items: center
        margin-left: 24px
        @media (max-width: 640px)
            margin-left: 0px
            margin-right: 0
            width: 100%
        &__input
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            background: white
            box-sizing: border-box
            padding: 10px
            width: 200px
            border: 1px solid rgba(0, 0, 0, 0.1)
            @media (max-width: 640px)
                width: 100%
            span
                text-overflow: ellipsis
                overflow: hidden
                white-space: nowrap
                font-family: 'Open Sans', sans-serif
                font-style: normal
                font-weight: 600
                font-size: 14px
                line-height: 17px
            
            & > div
                margin-top: 2px
                margin-left: auto
                svg
                    transition: all .4s ease
            
            .rotated
                svg
                    transform: rotate(180deg)
        
        &__toggle-list
            position: absolute
            display: flex
            flex-direction: column
            height: 0
            max-height: 240px
            padding: 0px 10px
            background: #FFFFFF
            border: 0px solid #ECEDF0

            width: 480px
            max-width: 500px
            box-sizing: border-box
            top: 42px
            left: 0px
            z-index: 102
            border-radius: 0 0 4px 4px 
            overflow: hidden
            overflow-y: scroll
            transition: height .4s ease, padding .4s ease, border .3s ease
            @media (max-width: 640px)
                max-width: calc(100vw - 48px)
            &::-webkit-scrollbar 
                width: 6px
                height: 5px
                margin: 30px
            &::-webkit-scrollbar-track 
                box-shadow: 0
                border-radius: 5px
                margin: 20px
            &::-webkit-scrollbar-thumb 
                background: #219653
                border-radius: 5px
                height: 10%

            & > div
                padding: 4px 10px
                width: 100%
                box-sizing: border-box
                transition: all .4s ease
                cursor: pointer
                border-radius: 4px

                &:hover
                    background: #f3f3f3
        
        .opened-toggle-list
            height: 220px
            transition: height .4s ease, padding .4s ease, border .3s ease
            border: 1px solid #ECEDF0
            padding: 4px 10px
            -webkit-box-shadow: 0px 8px 10px 6px rgba(63, 73, 81, 0.07)
            -moz-box-shadow: 0px 8px 10px 6px rgba(63, 73, 81, 0.07)
            box-shadow: 0px 8px 10px 6px rgba(63, 73, 81, 0.07)

    .green-button
        margin-left: auto

    &__dates-wrapper
        display: flex
        flex-direction: row
        align-items: center
        margin-left: 4px
        @media (max-width: 640px)
            width: 100%
            margin-right: auto
            margin: 12px auto 0 0px
            justify-content: space-between
        span
            margin: 0 10px
            font-family: 'Open Sans', sans-serif
            font-style: normal
            font-weight: 600
            font-size: 16px
            line-height: 20px
            color: #000000
            opacity: 0.6
        div
            background: #FFFFFF
            position: relative
            border: 1px solid rgba(0, 0, 0, 0.1)
            box-sizing: border-box
            padding: 12px
            display: flex
            flex-direction: row
            align-items: center
            width: 143px
            svg
                position: absolute
                right: 10px
                pointer-events: none
                top: 8px
                path
                    stroke: rgba(0,0,0,.6)
                background: #fff
                cursor: pointer
            input
                border: none
                outline: none
                width: 100%
                color: rgba(0, 0, 0, 0.43)
                font-family: "Open Sans", sans-serif
                font-weight: 600
                &::placeholder
                    font-family: 'Open Sans', sans-serif
                    font-style: normal
                    font-weight: 600
                    font-size: 16px
                    line-height: 20px
                    color: rgba(0, 0, 0, 0.43)

    &__buttons
        display: flex
        flex-direction: row
        align-items: center
        margin-left: 5px
        @media (max-width: 640px)
            width: 100%
            margin: 12px auto 12px 0px
            justify-content: space-between
        & > div
            background: white
            box-sizing: border-box
            padding: 10px 30px
            font-family: 'Open Sans', sans-serif
            font-style: normal
            font-weight: 600
            font-size: 17px
            line-height: 21px
            color: #000000
            cursor: pointer
            border: 1px solid rgba(0, 0, 0, 0.1)
            transition: all .4s ease
            margin: 0 5px
            @media (max-width: 640px)
                margin: 0
            &:hover
                color: #219653
                border: 1px solid #219653

    &__mobile-buttons
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        width: 100%

.column
    flex-direction: column  
    transition: height .4s ease, max-width .4s ease
    overflow: hidden
    position: relative