.end-consultation-container
    position: relative
    right: 0
    margin-left: auto
    width: 100%
    height: 100%
    display: flex
    flex-direction: column

    box-sizing: border-box
    padding: 48px 40px 120px 40px

    overflow-y: scroll
    @media (max-width: 640px)
        padding: 24px 26px 64px 22px
        width: 100%
        max-width: 100%

    &::-webkit-scrollbar 
        width: 6px
        height: 5px
        margin: 30px
    &::-webkit-scrollbar-track 
        box-shadow: 0
        border-radius: 5px
        margin: 20px
    &::-webkit-scrollbar-thumb 
        background: #219653
        border-radius: 5px
        height: 10%

    &__header
        display: flex
        flex-direction: column
        margin-top: 36px

        &__title
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 24px
            line-height: 28px
            color: #211E1E
            @media (max-width: 640px)
                font-size: 16px
    &__main-section
        display: flex
        flex-direction: column
        width: 100%
        margin-top: 24px
        box-sizing: border-box
        padding-bottom: 40px

        &__panel
            display: flex
            flex-direction: column
            width: 100%
            margin-top: 18px

            span
                font-family: 'Montserrat', sans-serif
                font-style: normal
                font-weight: 400
                font-size: 14px
                line-height: 17px
                color: #211E1E
                opacity: 0.5
                margin-bottom: 8px
            
            div
                width: 100%
                height: fit-content
                background: #FFFFFF
                border-radius: 12px
                box-sizing: border-box
                padding: 8px 16px

                textarea
                    border: none
                    width: 100%
                    height: 100%
                    min-height: 150px
                    resize: none
                    outline: none

                    font-family: Montserrat, sans-serif
                    font-style: normal
                    font-weight: normal
                    font-size: 16px
                    line-height: 20px

                    color: #211E1E
                    @media (max-width: 640px)
                        min-height: 100px
                    &:active
                        border: none
                        outline: none
                    &:focus
                        border: none
                        outline: none
                    &::placeholder
                        font-family: Montserrat, sans-serif
                        font-style: normal
                        font-weight: normal
                        font-size: 16px
                        line-height: 20px

                        color: rgba(33, 30, 30, 0.5)
    
    &__bottom-control-panel
        position: absolute
        margin-top: auto
        bottom: 0
        right: 0
        width: 100%
        height: fit-content
        padding: 20px 30px
        box-sizing: border-box
        display: flex
        flex-direction: row
        align-items: center
        justify-content: flex-end
        background: #FFFFFF
        z-index: 100

        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between

        transition: all .8s ease-in-out

        @media (max-width: 640px)
            justify-content: space-between
            padding: 7px 18px 11px 18px

        &__right-side
            display: flex
            flex-direction: row
            align-items: center
            width: 100%
            justify-content: right
            span
                display: flex
                flex-direction: row
                align-items: center
                margin-right: 24px

                font-family: 'Open Sans', sans-serif
                font-style: normal
                font-weight: 400
                font-size: 14px
                line-height: 20px
                color: #211E1E
                svg
                    margin-right: 8px

        .white-button
            margin-right: 16px
            @media (max-width: 640px)
                margin: 0
        .green-button
            max-width: 120px
            width: fit-content
