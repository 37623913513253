.partner-info-statistic
    display: flex
    flex-direction: column
    justify-content: space-between
    background: #FFFFFF
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07)
    border-radius: 12px
    box-sizing: border-box
    padding: 10px
    
    ///import css-variables from 
    //min-width: fit-content
    max-width: 320px
    width: 100%
    // max-width: 500px
    margin-top: 38px
    @media (max-width: 640px)
        display: flex
        flex-direction: column
        margin: 0 auto
        margin-bottom: 10px
        box-shadow: none
    &__additional-text
        width: 96%
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: 400
        font-size: 19px
        line-height: 21px

        color: #000000
        @media (max-width: 640px)
            font-size: 14px
            line-height: 17px
    .column
       
        display: flex
        flex-direction: column
        justify-content: flex-start
        & > div
            display: flex
            flex-direction: column
            align-items: flex-start
   
    div
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: 700
        font-size: 12px
        line-height: 28px
        color: #000000
    span
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: 400
        font-size: 12px
        line-height: 16px
        color: #211E1E
    .row
        display: flex
        flex-direction: row
        align-items: center
    &__values
        width: fit-content
        display: flex
        flex-direction: row
        align-items: center
        justify-content: flex-start
        & > div
            width: fit-content
            margin-right: 15px
            display: flex
            flex-direction: row
            align-items: center
            justify-content: flex-start
            span
                margin-left: 5px
    &__name
        display: flex
        flex-direction: row
        align-items: center
        justify-content: flex-start
        div
            width: fit-content
        span
            margin-left: 16px
.partner-info-statistic__name
    flex-direction: row !important
    align-items: center !important
.noMarginForP
    p
        margin: 0