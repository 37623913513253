.consultation-review-wrapper
    position: relative
    right: 0
    margin-left: auto
    height: 100%
    max-height: 100%
    display: flex
    flex-direction: column
    overflow: hidden
    padding-bottom: 100px
    &::-webkit-scrollbar 
        width: 6px
        height: 5px

    &::-webkit-scrollbar-track 
        box-shadow: 0
        border-radius: 5px

    &::-webkit-scrollbar-thumb 
        background: #219653
        border-radius: 5px
        height: 10%

    &__title
        margin: 38px 24px 0 24px
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: 700
        font-size: 36px
        line-height: 38px
        color: #211E1E
        @media (max-width: 640px)
            font-size: 20px
            line-height: 24px
            margin-top: 20px

    &__question-panel
        display: flex
        flex-direction: column
        margin: 24px 24px 0 24px
        &__title
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 700
            font-size: 24px
            line-height: 24px
            color: #000000
            @media (max-width: 640px)
                font-size: 16px
                line-height: 20px
        &__sub-title
            margin: 8px 0
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 400
            font-size: 20px
            line-height: 24px
            color: #000000
            opacity: 0.8
            @media (max-width: 640px)
                font-size: 16px
                line-height: 20px
    
    &__text-panel
        display: flex
        flex-direction: column
        margin: 0 24px
        @media (max-width: 640px)
            margin-top: 12px
            padding-bottom: 120px
        span
            margin: 8px 0
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 400
            font-size: 14px
            line-height: 17px
            color: #211E1E
            opacity: 0.8
        div
            width: 100%
            height: fit-content
            background: #FFFFFF
            border-radius: 12px
            box-sizing: border-box
            padding: 8px 16px

            textarea
                border: none
                width: 100%
                height: 100%
                min-height: 150px
                resize: none
                outline: none

                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: normal
                font-size: 16px
                line-height: 20px

                color: #211E1E
                @media (max-width: 640px)
                    min-height: 100px
                &:active
                    border: none
                    outline: none
                &:focus
                    border: none
                    outline: none
                &::placeholder
                    font-family: Montserrat, sans-serif
                    font-style: normal
                    font-weight: normal
                    font-size: 16px
                    line-height: 20px

                    color: rgba(33, 30, 30, 0.5)
    &__bottom-control-panel
        position: absolute
        margin-top: auto
        bottom: 0
        right: 0
        width: 100%
        height: fit-content
        padding: 20px 30px
        box-sizing: border-box
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1)
        display: flex
        flex-direction: row
        align-items: center
        justify-content: flex-end
        background: #FFFFFF
        z-index: 100

        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between

        transition: all .8s ease-in-out

        @media (max-width: 640px)
            justify-content: space-between
            padding: 7px 18px 11px 18px


        .white-button
            margin-right: 16px
            @media (max-width: 640px)
                margin: 0
        .green-button
            right: 0
            margin-left: auto
            max-width: 120px
            width: fit-content

.desktop-choose-consultation-rate-panel
    display: flex
    flex-direction: row
    align-items: center
    width: 464px
    position: relative
    &__line
        position: absolute
        left: 0px
        width: 100%
        height: 1px
        background: #9292A3
        z-index: 1
    &__rating
        width: 32px
        height: 32px
        box-sizing: border-box
        border: 1px solid #9292A3
        border-radius: 50%

        display: flex
        justify-content: center
        align-items: center

        font-family: Open Sans, sans-serif
        font-style: normal
        font-weight: 600
        font-size: 12px
        line-height: 20px
        color: #9292A3
        background: #F4F4F4
        z-index: 4
        margin-right: 16px
        cursor: pointer
        transition: all .4s ease
        &:hover
            color: #219653
            border-color: #219653
    .choosen
        border-color: #219653
        background: #219653
        color: #FFFFFF

.mobile-choose-consultation-rate-panel-wrapper
    display: flex
    flex-direction: column
    align-items: center
    width: 100%
    &__header
        width: 340px
        position: relative
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        box-sizing: border-box
        padding: 6px
        div
            font-family: Open Sans, sans-serif
            font-style: normal
            font-weight: 400
            font-size: 16px
            line-height: 20px
            color: #000000
.mobile-choose-consultation-rate-panel
    width: 340px
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between

    position: relative
    box-sizing: border-box
    padding: 6px
    border: 1px solid #219653
    border-radius: 10px

    &__dot
        background: #38B23D
        width: 10px 
        height: 10px
        border-radius: 50%
    .choosen
        background: #24743A
    .drag-icon
        position: absolute
        //margin-right: 24px
        left: -15px
        right: 100px
        border: 2px solid #219653
        width: 45px 
        height: 45px
        border-radius: 50%
        pointer-events: none
    &__progress-bar
        position: absolute
        height: 100%
        margin-left: -6px
        border-radius: 10px
        pointer-events: none
        border-right: 2px solid #219653
        background: rgba(33, 150, 83, 0.17)