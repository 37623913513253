.search-filter-consultant-wrapper
    display: flex
    flex-direction: row
    width: 100%
    position: relative

    margin-top: 8px
    @media (max-width: 640px)
        flex-direction: column 
    & > div
        display: flex
        flex-direction: row
        position: relative
        @media (max-width: 640px)
            margin-bottom: 11px
            .search-filter-consultant-input:last-of-type
                margin-right: 0
        span
            position: absolute
            bottom: 98%
            margin-left: 4px
            font-family: Open Sans, sans-serif
            font-style: normal
            font-weight: normal
            font-size: 12px
            line-height: 24px
            color: #211E1E

        .search-filter-consultant-input
            display: flex
            flex-direction: row
            justify-content: space-between
            align-items: center
            position: relative
            box-sizing: border-box
            padding: 10px 20px
            background: #FFFFFF
            border: 1px solid #ECEDF0
            box-sizing: border-box
            border-radius: 4px
            margin-right: 8px
            align-items: center
            width: 100%
            @media (max-width: 640px)
                padding: 10px
            input
                outline: none
                border: none
                width: 100%
                background: white
                align-items: center
                margin: 0
                &::placeholder
                    font-family: Open Sans, sans-serif
                    font-style: normal
                    font-weight: normal
                    font-size: 16px
                    line-height: 20px
                    color: #9292A3

            &__icon
                width: fit-content
                margin-left: 2px
                height: 100%
                display: flex
                justify-content: center
                align-items: center
                svg
                    transition: all .4s ease
        .activities-toggle-list
            position: absolute
            display: flex
            flex-direction: column
            height: 0
           
            padding: 0px 10px
            background: #FFFFFF
            border: 0px solid #ECEDF0

            width: 100%
            max-width: 276px
            box-sizing: border-box
            margin-top: 44px
            z-index: 102
            border-radius: 0 0 4px 4px 
            overflow: hidden
            transition: height .4s ease, padding .4s ease, border .3s ease

            & > div
                padding: 4px 10px
                width: 100%
                box-sizing: border-box
                transition: all .4s ease
                cursor: pointer
                border-radius: 4px

                &:hover
                    background: #f3f3f3
        
        .opened-activities-toggle-list
            height: fit-content
            transition: height .4s ease, padding .4s ease, border .3s ease
            border: 1px solid #ECEDF0
            padding: 4px 10px
            -webkit-box-shadow: 0px 8px 10px 6px rgba(63, 73, 81, 0.07)
            -moz-box-shadow: 0px 8px 10px 6px rgba(63, 73, 81, 0.07)
            box-shadow: 0px 8px 10px 6px rgba(63, 73, 81, 0.07)
                    
    &__buttons-wrapper
        display: flex
        flex-direction: row
        margin-right: 40px
        position: relative
        & > div
            box-sizing: border-box
            padding: 10px 16px
            background: #FFFFFF
            border: 1px solid #ECEDF0
            box-sizing: border-box
            border-radius: 4px

            display: flex
            justify-content: center
            align-items: center

            font-family: Open Sans, sans-serif
            font-style: normal
            font-weight: 600
            font-size: 14px
            line-height: 20px
            color: #9292A3

            cursor: pointer
            transition: all .4s ease
            &:hover
                color: #219653
                border: 1px solid #219653
    &__list-wrapper
        position: relative
        max-width: 276px
        width: 100%
        font-family: Open Sans, sans-serif
        font-style: normal
        font-weight: normal
        font-size: 16px
        line-height: 24px

        color: #211E1E
        &__top-side
            position: relative
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between

            box-sizing: border-box
            padding: 10px 20px
            background: #FFFFFF
            border: 1px solid #ECEDF0
            box-sizing: border-box
            border-radius: 4px
            margin-right: 8px
            min-width: 100%
            @media (max-width: 640px)
                margin-right: 0
            &__icon
                cursor: pointer
                width: 20%
                height: 100%
                display: flex
                justify-content: center
                align-items: center
                svg
                    transition: transform .8s ease
                    margin-left: auto


        
        &__toggle-list
            position: absolute
            display: flex
            flex-direction: column
            height: 0
            max-height: 120px

            padding: 0px 10px
            background: #FFFFFF
            border: 0px solid #ECEDF0

            width: 100%
            max-width: 276px
            box-sizing: border-box
            margin-top: 42px
            z-index: 102
            border-radius: 0 0 4px 4px 
            overflow: hidden
            transition: height .4s ease, padding .4s ease, border .3s ease
            & > div
                padding: 4px 10px
                width: 100%
                box-sizing: border-box
                transition: all .4s ease
                cursor: pointer
                border-radius: 4px
                &:hover
                    background: #f3f3f3
        
        .opened-toggle-list
            height: 74px
            transition: height .4s ease, padding .4s ease, border .3s ease
            border: 1px solid #ECEDF0
            padding: 4px 10px
                    
.rotated
    svg
        transform: rotate(180deg)
.search-filter-consultant-wrapper__list-wrapper
    @media (max-width: 640px)
        margin-top: 20px
