.login-form-wrapper
    max-width: 332px
    display: flex
    flex-direction: column
    position: relative
    div
        position: relative

    .text-input
        margin: 8px 0
    .green-button
        margin-top: 24px
        width: 332px

    &__forgot-password-button
        margin-top: 16px
        width: fit-content
        font-family: Open Sans, sans-serif
        font-style: normal
        font-weight: 600
        font-size: 16px
        line-height: 20px
        text-decoration-line: underline
        color: #219653
        @media (max-width: 640px) 
            width: 100%
            text-align: center
.frameClass
    position: fixed
    top: 5%
    left: 5%
    width: 90vw
    height: 90vh
    z-index:999