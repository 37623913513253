.rating-measurement-container
    position: relative
    margin-left: auto
    right: 0
    width: 100%
    height: calc(100% - 72px)
    max-height: calc(100% - 72px)
    display: flex
    flex-direction: column
    box-sizing: border-box
    overflow: hidden
    padding-bottom: 10px
    box-sizing: border-box
    z-index: 90
    
    @media (max-width: 640px)
        width: 100%
        max-width: 100%

    &__rating-panels
        width: 100%
        max-width: calc(100% - 70px)
        margin-top: auto
        @media (max-width: 640px)
            margin-top: 0
            max-width: none
            overflow-y: scroll
            padding-bottom: 62px
            max-height: 57vh
            
           
    &__title-wrapper
        position: relative
        display: flex
        justify-content: center
        align-items: center
        margin-top: 5vh
        @media (max-width: 640px)
            margin-top: 3vh
            width: 100%
            flex-direction: column
            align-items: center
            text-align: center
        &__title
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 24px
            line-height: 29px
            color: #211E1E
            span
                color: #9292A3
        &__measurement-type
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            width: 100%
            box-sizing: border-box
            padding: 0 4px
            margin-top: 2.7vh
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 10px
            line-height: 24px
            letter-spacing: 1px
            text-transform: uppercase
            color: #211E1E
            @media (max-width: 640px)
                margin-top: 0
            span
                max-width: 40%
                overflow: hidden
                white-space: nowrap
                text-overflow: ellipsis
            .type
                max-width: 60%
        .horizontal_line
            margin-top: 10px
            width: 100%
            height: 2px
            background: #C4C4C4
    &__description-wrapper
        display: flex
        flex-direction: row
        align-items: center
        justify-content: center
        margin: 20px auto 0 auto

        @media (max-width: 640px)
            width: 100%
            margin: 0 auto
            margin-top: 10px
        &__definition
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 400
            font-size: 18px
            line-height: 24px
            color: #211E1E
            width: fit-content
            text-align: left
            
            display: flex
            margin-left: -15vw
            
            align-items: flex-end
            justify-content: flex-end
            @media (max-width: 640px)
                max-width: 45%
                margin-left: 0vw
                font-size: 16px
            span
                display: flex
                flex-direction: row
                align-items: center
                svg
                    margin: 0 12px

        &__person
            display: flex
            flex-direction: column

            .name
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: bold
                font-size: 24px
                line-height: 24px
                color: #211E1E
            .role
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: normal
                font-size: 14px
                line-height: 24px
                color: rgba(33, 30, 30, 0.5)
.bottom_buttons
    position: fixed
    right: 0
    bottom: 0
    display: flex
    padding: 15px
    background: #fff
    width: 100%
    justify-content: flex-end
    z-index: 1000
    .green-button
        margin-left: 15px
        width: fit-content
