.measurement-statistic-header
    display: flex
    flex-direction: column
    font-family: Montserrat, sans-serif
    
    &__title
        font-style: normal
        font-weight: 700
        font-size: 36px
        line-height: 50px
        color: #211E1E
        @media (max-width: 640px)
            font-size: 20px
            line-height: 24px

    &__comment
        margin: 4px 0
        font-style: normal
        font-weight: 700
        font-size: 18px
        line-height: 22px
        color: #000000
        @media (max-width: 640px)
            font-size: 16px
            line-height: 20px

    &__description
        margin-top: 4px
        display: flex
        flex-direction: row
        align-items: center
        @media (max-width: 640px)
            flex-direction: column
            align-items: flex-start
        &__row
            display: flex
            flex-direction: row
            align-items: center
            margin-bottom: 4px
            margin-right: 120px
            @media (max-width: 640px)
                margin-right: 0
            &__title
                font-style: normal
                font-weight: 400
                font-size: 14px
                line-height: 17px
                /* identical to box height */

                display: flex
                align-items: center

                color: #211E1E

                opacity: 0.5
            &__sub-title
                font-style: normal
                font-weight: 600
                font-size: 16px
                line-height: 20px

                color: #000000

