.signup-confirmation-wrapper
    max-width: 332px
    display: flex
    flex-direction: column
    position: relative
    div
        position: relative

    .text-input
        margin: 8px 0

    .green-button
        margin-top: 24px
        width: 332px