
.show__to__buye
    display: flex
    flex-direction: column
    align-items: center
    background:#fff
    position: absolute
    z-index: 500
    width: 210px
    padding: 30px
    border-radius: 10px
    left: 50%
    top: 50%

    transform: translateX(-50%) translateY(-50%)

    font-size: 16px
    text-align: center
    width: 220px !important
.noShow
    display: none