.left-side-instruction-wrapper
    position: absolute
    align-self: center
    //margin: auto 0
    top: calc(50% - 240px)
    left: 0px
    width: fit-content
    height: 480px

    display: flex
    flex-direction: row
    align-items: center

    transition: left 0.6s ease
    z-index: 998
    @media (max-wdith: 640px)
        left: 0px

    &__opening-panel
        position: relative
        display: flex
        justify-content: center
        align-items: center
        width: 24px
        height: 167px
        background: #FFFFFF
        border-radius: 0px 10px 10px 0px
        box-shadow: inset 0px -4px 4px rgba(0, 0, 0, 0.15), inset 0px 4px 4px rgba(0, 0, 0, 0.15)
        z-index: 991
        cursor: pointer
        
        @media (max-width: 640px)
            width: 19px

        &__arrow-icon
            position: relative
            margin: auto 0
        &__info-icon
            position: absolute
            top: 20px
            transform: scale(0.8)

    &__text-panel
        width: 0px
        height: 480px
        overflow: hidden
        box-sizing: border-box
        background: #FFFFFF
        border: 0px solid rgba(0, 0, 0, 0.3)
        box-sizing: border-box
        border-radius: 0px 20px 20px 0
        transition: width .6s ease, border .4s ease
        overflow-y: scroll
        
        &::-webkit-scrollbar 
            width: 6px
            height: 5px
            margin: 30px
        &::-webkit-scrollbar-track 
            box-shadow: 0
            border-radius: 5px
            margin: 20px
        &::-webkit-scrollbar-thumb 
            background: #219653
            border-radius: 5px
            height: 10%
        div
            width: 884px
            margin: 41px 29px

            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: normal
            font-size: 14px
            line-height: 17px
            color: #211E1E

            @media (max-width: 640px)
                width: 300px
                margin: 6px
                        
    .left-side-instruction-text-panel-opened
        
        border-width: 1px
        transition: width .6s ease, border .4s ease
