.my-account-photo-wrapper
    display: flex
    flex-direction: row
    align-items: center
    margin: 0 25px
    cursor: pointer
    & > div
        width: 100px
        height: 100px
        border-radius: 50%
        background: grey
        cursor: default
        overflow: hidden
        img 
            object-fit: cover
            width: 100%
            height: 100%
    
    span
        margin-left: 24px
        font-family: Open Sans, sans-serif
        font-style: normal
        font-weight: 600
        font-size: 16px
        line-height: 20px
        text-decoration-line: underline

        color: #219653
        cursor: pointer
        transition: all .4s ease

        &:hover
            color: #1e8f4f

    form
        cursor: pointer
    input
        cursor: pointer
        position: absolute
        opacity: 0