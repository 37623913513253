.measurement-statistic-container
  position: relative
  margin-left: auto
  right: 0
  width: 100vw
  height: fit-content
  display: flex
  flex-direction: column
  
  overflow-y: scroll
  overflow-x: hidden

  box-sizing: border-box

  padding: 30px 0px
  
  & > div
    margin-left: 24px
    margin-right: 24px
    width: calc(100% - 40px)
    @media screen and ( max-width: 640px )
      margin-left: 4px
      margin-right: 4px
      width: calc(100% - 6px)
      &:first-of-type
        padding-left: 16px
  &::-webkit-scrollbar
    width: 6px
    height: 5px
    margin: 30px
  &::-webkit-scrollbar-track
    box-shadow: 0
    border-radius: 5px
  &::-webkit-scrollbar-thumb
    background: #219653
    border-radius: 5px
    height: 10%
  
  @media screen and ( max-width: 640px )
    padding: 8px 0px 100px 0px

.row
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  width: fit-content
  box-sizing: border-box
  padding: 0 auto
  & > div
    width: fit-content
    display: flex
    flex-direction: row
    
    justify-content: center
.error
  margin-left: 400px
  margin-top: 100px
  font-size: 36px
  @media screen and ( max-width: 640px )
    display: flex
    flex-direction: column
    align-items: center
    margin: 0
    font-size: 30px

.preloader
  position: fixed
  margin-right: 0 !important
  width: 100vw !important
  z-index: 99999
  display: flex
  align-items: center
  justify-content: center
  height: 100vh
  background:#fff
  top:0
  right:0
.preloader
  div
    display: flex
    flex-direction: column
    align-items: center
    p
      font-size: 20px
      font-weight: bold
.preloader__image 
  
  width: 70px
  height: 70px
  
  text-align: center
  animation: preloader-rotate 2s infinite linear


@keyframes preloader-rotate 
  100% 
    transform: rotate(360deg)
  

.loaded_hiding .preloader 
  transition: 0.3s opacity
  opacity: 0


.loaded .preloader 
  display: none



