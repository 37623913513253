.login-container
    box-sizing: border-box
    padding: 40px 24px
    display: flex
    flex-direction: row
    @media (max-width: 640px)
        width: 100%
        flex-direction: column
        padding: 40px 0
        align-items: center
        
.login-title
    font-family: Montserrat, sans-serif
    font-style: normal
    font-weight: bold
    font-size: 36px
    line-height: 24px
    color: #211E1E
    margin-bottom: 32px
    max-width: 448px
    width: 100%
    @media (max-width: 640px)
        font-size: 24px
        line-height: 28px
        margin-bottom: 24px
.login-sub-title
    max-width: 440px
    font-family: Montserrat, sans-serif
    font-style: normal
    font-weight: bold
    font-size: 24px
    line-height: 28px
    color: #211E1E
    opacity: 0.54
    margin-bottom: 40px
    @media (max-width: 640px) 
        font-size: 16px
        margin-bottom: 48px

.login-error-panel
    width: 100%
    box-sizing: border-box
    padding: 10px 16px
    background: #E3655D
    border-radius: 4px
    display: flex
    flex-direction: column
    text-align: center
    font-family: Open Sans, sans-serif
    font-style: normal
    color: #FFFFFF
    margin-bottom: 32px
    transition: 0.3s
    &__title
        font-weight: 600
        font-size: 16px
        line-height: 20px
    &__sub-title
        font-weight: 300
        font-size: 14px