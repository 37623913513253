.person-list-measurement-container
    position: relative
    margin-left: auto
    right: 0
    width: 100%
    height: calc(100% - 72px)
    max-height: calc(100% - 72px)
    display: flex
    flex-direction: column

    overflow-y: scroll
    overflow-x: hidden
    padding-bottom: 10px
    box-sizing: border-box
    padding-left: 13px
    &::-webkit-scrollbar 
      width: 6px
      height: 5px

    &::-webkit-scrollbar-track 
      box-shadow: 0
      border-radius: 5px

    &::-webkit-scrollbar-thumb 
      background: #219653
      border-radius: 5px
      height: 10%
    @media (max-width: 640px)
        width: 100%
        max-width: 100%

    &__title-wrapper
        display: flex
        flex-direction: row
        align-items: center
        width: 100%

        margin: 3vh 40px 18px 40px // dsafksdajfvoijdfoiugh34iohjrvoirehgvjeroivhjuidf
        padding: 0
        height: fit-content
        box-sizing: border-box
        justify-content: space-between

        @media screen and ( max-width: 640px )
            margin: 0
            margin-top: 10px
            max-width: 322px
            margin-left: 15px

        &__title
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 24px
            line-height: 24px
            color: #211E1E


    &__inside-wrapper
        margin: 1vh 40px 18px 40px
        display: flex
        flex-direction: column
        align-items: flex-start
        @media ( max-width: 640px )
            margin: 0 auto
            margin-top: 14px
            width: calc(100vw - 25px)
            padding-bottom: 30px
        &__row
            display: flex
            flex-direction: row
            align-items: center
            margin-bottom: 24px
            @media screen and ( max-width: 640px )
                flex-direction: column
                align-items: flex-start
                width: 100%
                margin-bottom: 20px
            span.title
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: normal
                font-size: 18px
                line-height: 24px
                color: #211E1E
                min-width: fit-content
                //width: 100%
                @media ( max-width: 640px )
                    margin-left: 1%
                    margin-top: 5px

    &__info-panel-wrapper
        display: flex
        flex-direction: row
        align-items: center
        margin: 0 auto
        margin-bottom: 19px
        span
            max-width: 220px
            margin: 0 29px

            font-family: Montserrat, sans-serif
            font-style: italic
            font-weight: 500
            font-size: 14px
            line-height: 17px

            color: #939393
        div
            transform: rotate(180deg)
            margin-bottom: 4px
.modal__person
    position: fixed
    width: 100vw
    height: 100vh
    z-index: 99999999
    top: 0
    left: 0
    background: rgba(0,0 ,0 , 0.6)
    display: flex
    align-items: center
    justify-content: center
    .modal__person__body
        background: #fff
        padding: 20px
        border-radius: 15px
        display: flex
        flex-direction: column
        align-items: center
        width: 450px
        @media ( max-width: 640px )
            width: 78%
        p
            font-size: 18px
            text-align: center
            margin-bottom: 40px
        .modal__person__buttons
            display: flex
            width: 100%
            justify-content: space-between
            @media ( max-width: 640px )
                flex-direction: column
            div
                width: 200px
                justify-content: center
                @media ( max-width: 640px )
                    width: 100%
                    margin-bottom: 15px