.types-ratio-diagram
    display: flex
    flex-direction: column
    width: fit-content
    position: relative
    margin-top: 10px
    max-width: 98%
    overflow: hidden
    font-family: Montserrat, sans-serif
    @media (max-width: 640px)
        max-width: calc(100% - 18px)
        margin: 10px 9px
    &__main-text
        width: 100%
        font-style: normal
        font-weight: 400
        font-size: 16px
        line-height: 20px

        color: #000000
    &__header
        display: flex
        flex-direction: row
        align-items: center
        margin-top: 24px
        max-width: 98%
        overflow: hidden
        @media (max-width: 1350px)
            display: flex
            flex-direction: column
            align-items: flex-start
        &__column
            display: flex
            flex-direction: column
            margin-right: 32px
            max-width: 40%
            width: fit-content
            
            &__type-panel
                width: 100%
                display: flex
                flex-direction: row
                align-items: center
                margin-bottom: 10px
                @media (max-width: 640px)
                    width: 98%
                &__color
                    width: 45px
                    max-width: 45px
                    max-height: 45px
                    min-width: 45px
                    min-height: 45px
                    height: 45px
                    border-radius: 7px
                    margin-right: 18px
                    @media (max-width: 640px)
                        max-height: 32px
                        max-width: 32px
                        min-width: 32px
                        min-height: 32px
                        margin-right: 8px
                span
                    font-style: normal
                    font-weight: 700
                    font-size: 18px
                    line-height: 28px
                    color: #000000
                    min-width: 280px
                    @media (max-width: 640px)
                        font-size: 14px
                        line-height: 17px
                        min-width: 220px
                &__percentage
                    margin-left: auto
                    display: flex
                    justify-content: center
                    align-items: center
                    background: #DDDDDD
                    border-radius: 5px
                    height: 21px
                    width: 58px
                    min-width: 58px
                    max-width: 58px
                    font-family: JetBrains Mono, monospace
                    font-style: normal
                    font-weight: 700
                    font-size: 16px
                    line-height: 21px
                    color: #000000
    &__graphic
        display: flex
        flex-direction: row
        align-items: center
        width: 100%
        height: 120px
        border-radius: 20px
        overflow: hidden
        position: relative
        & > div
            height: 100%
            display: flex
            justify-content: center
            align-items: center
            span
                font-family: JetBrains Mono, monospace
                font-style: normal
                font-weight: 700
                font-size: 16px
                line-height: 21px
                text-align: center
                color: #FFFFFF
                margin-top: -24px
                @media (max-width: 640px)
                    font-size: 12px
        &__line
            position: absolute
            width: 100%
            height: 3px
            max-height: 3px
            background: rgba(255, 255, 255, 0.6)
            z-index: 100
            top: 57px
        &__vertical-line
            position: absolute
            width: 3px
            max-width: 3px
            max-height: 25px
            background: rgba(255, 255, 255, 0.6)
            height: 25px
    .small-graphic
        height: 68px
        .small-line
            top: 31px

    &__axis
        width: 100%
        position: relative
        display: flex
        justify-content: center
        align-items: center
        height: 26px
        margin: 4px 0
        &__vertical-line
            position: absolute
            max-width: 3px
            max-height: 25px
            height: 25px
            z-index: 100
            background: #219653
        &__line
            position: absolute
            width: 100%
            height: 3px
            background: #219653
            top: 10px
            z-index: 1
        span
            box-sizing: border-box
            width: 60px
            background: #F4F4F4
            font-family: JetBrains Mono, monospace
            font-style: normal
            font-weight: 400
            font-size: 16px
            line-height: 21px
            text-align: center
            z-index: 100
            color: #000000
    
    &__additional-text
        width: 100%
        display: flex
        flex-direction: column
        align-items: flex-start
        span
            font-style: normal
            font-weight: 400
            font-size: 16px
            line-height: 28px
            color: #000000
        div
            font-weight: 400
            font-size: 16px
            line-height: 20px

            color: #000000
