.choose-gender-measurement-person-wrapper
    display: flex
    flex-direction: row
    align-items: center
    @media (max-width: 640px)
        flex-direction: column
        align-items: flex-start
        margin: 8px 0px
    div
        display: flex
        flex-direction: row
        align-items: center
        

    &__gender-panel
        display: flex
        flex-direction: row
        align-items: center

        background: #FFFFFF
        box-sizing: border-box
        padding: 11px 9px 11px 9px
        border-radius: 20px
        margin-right: 10px

        cursor: pointer
        @media (max-width: 640px)
            margin-bottom: 8px
        span
            margin-left: 8px
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: normal
            font-size: 14px
            line-height: 24px
            color: #211E1E
            @media (max-width: 640px)
                width: auto
                overflow: hidden
    
        &__choosen-checkbox
            height: 22.5px
            width: 25px
            background: #219653
            border: 1px solid #219653
            box-sizing: border-box
            border-radius: 50%
        
        &__non-choosen-checkbox
            height: 22.5px
            width: 25px
            border: 1px solid #219653
            box-sizing: border-box
            border-radius: 50%
    &__birth-date-panel
        display: flex
        background: #FFFFFF
        padding: 14px 20px
        box-sizing: border-box
        border-radius: 20px 
       
        @media (max-width: 640px)
            width: 100%
            height: 42px
            overflow: hidden
            margin-bottom: 8px
            //min-width: 320px
        input
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: normal
            font-size: 14px
            line-height: 24px
            color: #211E1E
            width: 100%
            outline: none
            border: none
            &::placeholder
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: normal
                font-size: 14px
                line-height: 17px
                color: rgba(33, 30, 30, 0.5)
                @media (max-width: 640px)
                    max-width: 155px
            