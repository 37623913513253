.login-recovery-container
    box-sizing: border-box
    padding: 40px 24px
    display: flex
    flex-direction: row
    @media (max-width: 640px)
        width: 100%
        flex-direction: column
        padding: 40px 0
        align-items: center
.login-recovery-title
    font-family: Montserrat, sans-serif
    font-style: normal
    font-weight: bold
    font-size: 36px
    line-height: 24px
    color: #211E1E
    margin-bottom: 32px
    width: 500px
    @media (max-width: 640px)
        max-width: 332px
        font-size: 24px
        line-height: 28px
        margin-bottom: 24px
.login-recovery-sub-title
    max-width: 440px
    width: 100%
    font-family: Montserrat, sans-serif
    font-style: normal
    font-weight: bold
    font-size: 24px
    line-height: 28px
    color: #211E1E
    opacity: 0.54
    margin-bottom: 40px
    @media (max-width: 640px) 
        max-width: 332px
        font-size: 16px
    @media (max-width: 332px)
        max-width: 304px

.login-recovery-error-panel
    width: 100%
    max-width: 331px
    box-sizing: border-box
    padding: 10px 16px
    background: #E3655D
    border-radius: 4px
    display: flex
    flex-direction: column
    text-align: center
    font-family: Open Sans, sans-serif
    font-style: normal
    color: #FFFFFF
    display: flex
    align-items: center
    margin-bottom: 32px
    span
        max-width: 212px
    &__title
        font-weight: 600
        font-size: 16px
        line-height: 20px
    &__sub-title
        font-weight: 300
        font-size: 14px