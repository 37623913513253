.login-recovery-first-stage-form-wrapper
    max-width: 448px
    display: flex
    flex-direction: column
    position: relative

    div
        max-width: 332px
        position: relative

    .text-input
        margin: 8px 0

    .green-button
        margin-top: 24px
        width: 332px