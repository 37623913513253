.start-measurement-container
    position: relative
    right: 0
    margin-left: auto
    width: 100%
    height: calc(97% - 72px)
    max-height: calc(100% - 72px)
    display: flex
    flex-wrap: wrap
    overflow: hidden

    &::-webkit-scrollbar 
        width: 6px
        height: 5px

    &::-webkit-scrollbar-track 
        box-shadow: 0
        border-radius: 5px

    &::-webkit-scrollbar-thumb 
        background: #219653
        border-radius: 5px
        height: 10%

    @media (max-width: 640px)
        height: 100%
        width: 100%
        max-width: 100%
        overflow-y: hidden
        box-sizing: border-box
        padding-bottom: 20px

    &__title-wrapper
        display: flex
        flex-direction: row
        align-items: center
        width: 100%

        margin: 3vh 40px 18px 40px // dsafksdajfvoijdfoiugh34iohjrvoirehgvjeroivhjuidf
        padding: 0
        height: fit-content
        box-sizing: border-box
        justify-content: space-between
        div
            position: relative
            right: 0

        span
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 24px
            line-height: 24px
            color: #211E1E
        @media (max-width: 640px)
            margin: 8px 40px 8px 40px

    .measurement-types-wrapper 
        display: flex
        flex-direction: row
        overflow: scroll
        width: fit-content
        position: relative
        padding-top: 20px
        padding-left: 3px
        margin-left: 30px
        &::-webkit-scrollbar
            width: 0px
            background: transparent

        @media (max-width: 640px)
            width: 100%
            margin-top: -8px
            overflow-y: hidden
            padding-left: 3px
            margin-left: 0px
            padding-top: 0
        &>div
            display: flex
            width: fit-content
            @media (max-width: 640px)
                overflow-y: hidden

    &__info-panel-wrapper
        display: flex
        flex-direction: row
        align-items: center
        margin-right: 200px
        @media (max-width: 640px)
            display: none
        span
            max-width: 439px
            margin: 0 29px

            font-family: Montserrat, sans-serif
            font-style: italic
            font-weight: 500
            font-size: 14px
            line-height: 17px

            color: #939393
        div
            transform: rotate(180deg)
            margin-bottom: 4px

    &__mobile-bottom-panel
        display: flex
        flex-direction: column
        align-items: center
        margin: 0 auto