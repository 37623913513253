.step-bar-wrapper
    position: absolute
    right: 40px
    display: flex
    flex-direction: row
    align-items: center
    @media (max-width: 640px)
        position: relative
        margin: 0 auto
        right: 0
        margin-left: 32px

    .step-wrapper
        display: flex
        flex-direction: row
        align-items: center
        z-index: 2
        &__circle
            display: flex
            align-items: center
            justify-content: center
            font-family: Open Sans, sans-serif
            font-style: normal
            font-weight: 600
            font-size: 12px
            line-height: 20px
            box-sizing: border-box
            width: 32px
            height: 32px
            border-radius: 32px
            position: relative
            z-index: 5
            @media (max-width: 640px)
                width: 16px
                height: 16px
                    

        .active
            color: #FFFFFF
            background: #219653
            border: 1px solid#219653

        .inactive
            color: #9292A3
            border: 1px solid #9292A3
            background: #F4F4F4
        
        &__additional-panel
            display: flex
            align-items: center
            height: 32px
            max-height: 32px
            background-color: rgba(225, 247, 236)
            border-radius: 0px 100px 100px 0px
            padding: 0 10px 2px 22px
            box-sizing: border-box
            margin-left: -14px
            z-index: 2

            font-family: Open Sans, sans-serif
            font-style: normal
            font-weight: 600
            font-size: 12px
            line-height: 20px
            color: #4BAB80

            @media (max-width: 640px)
                margin-left: -10px
                padding: 0 2px 3px 12px
                height: 16px

        &__vertical-line
            background: #9292A3
            position: relative
            width: 16px
            height: 1px
            z-index: 1
            @media (max-width: 640px)
                width: 12px