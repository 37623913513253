.group-characteristic
    display: flex
    flex-direction: row
    
    justify-content: space-between
    flex-wrap: wrap
    @media (max-width: 640px)
        flex-direction: column
        align-items: center
    & > div
        max-width: calc(25% - 7.5px)
        margin-bottom: 12px
        background: #FFFFFF
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
        border-radius: 5px
        box-sizing: border-box
        padding: 8px

        display: flex
        flex-direction: column
        @media (max-width: 640px)   
            max-width: 90%
            height: fit-content
            margin-bottom: 12px
        .title
            height: 56px
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 700
            font-size: 14px
            line-height: 17px

            color: #000000
        
        & > div
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            margin-bottom: 10px
            span
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: 700
                font-size: 12px
                line-height: 15px
                color: #000000

            div
                display: flex
                justify-content: center
                align-items: center
                width: 60px
                height: 24px
                background: #DDDDDD
                border-radius: 5px

                font-family: JetBrains Mono, monospace
                font-style: normal
                font-weight: 700
                font-size: 12px
                line-height: 16px
                color: #000000

        .desc
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 400
            font-size: 14px
            line-height: 17px

            color: #000000
            margin-top: 10px