.navbar-wrapper
    position: absolute
    z-index: 992
    top: 0
    left: 0
    height: 100vh
    max-height: 100%
    max-width: 260px
    width: 0
    background-color: #fff
    border-right: 1px solid #E6E6E8
    display: flex
    flex-direction: column
    align-items: flex-start
    overflow: hidden
    transition: width 0.6s ease
    @media (max-width: 640px)
        z-index: 9992

    .navbar-logo
        margin: 33px 0 32px 0
        align-self: center
        img
            width:70px
    span.navbar-title
        position: relative
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: normal
        font-size: 16px
        line-height: 24px
        transition: 0.3s
        height: 40px
        color: #535256
        display: flex
        flex-direction: row
        align-items: center
        text-align: left
        width: fit-content
        overflow: hidden
        margin: 6px 0 
        padding-left: 24px
        box-sizing: border-box
        cursor: pointer
        svg 
            margin-right: 16px
        a
            color: #535256
            height: 100%
            width: 100%
            display: flex
            align-items: center

    span.current-navbar-title
        transform: translateX(5px)
        font-weight: 700 !important
        color: #211E1E
    .navbar-account
        cursor: pointer
        display: flex
        flex-direction: column
        position: relative
        width: 100%
        border-top: 1px solid #E6E6E8
        border-bottom: 1px solid #E6E6E8
        margin-top: 10px
        span.navbar-title
            width: 100%
            margin-left: 16px
            white-space: pre-line
            overflow: hidden
            a
                &::active
                   color: #E6E6E8
                    
        &__info-wrapper
            position: relative
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            box-sizing: border-box
            padding: 12px 0
            div
                display: flex
                flex-direction: row
                align-items: center
                position: relative
                    
            .openingArrow
                transition: all 0.8s ease
                margin-right: 24px
                cursor: pointer
                svg 
                    transition: all 0.8s ease

            &__circle
                display: flex
                justify-content: center
                align-items: center
                width: 40px
                height: 40px
                border-radius: 50%
                overflow: hidden
                background: #E6E6E8
                margin-left: 24px
                img
                    width: 100%
                    height: 100%
                    object-fit: cover
            
            &__name
                margin-left: 12px
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: 500
                font-size: 13px
                line-height: 17px
                max-width: 142px
                color: #211E1E

        .navbar-account-panel
            position: relative 
            max-height: 0
            transition: max-height 0.8s ease-out
            overflow: hidden
        .navbar-account-panel-opened
            max-height: 500px
            transition: max-height 0.8s ease-in


    .navbar-toggle-menu-state-panel
        position: absolute
        bottom: 0
        left: 0
        width: 100%
        box-sizing: border-box
        background: #FFFFFF
        padding: 12px 0px 24px 24px

        span
            cursor: pointer

.navbar-wrapper-opened
    width: 260px
.navbar-title:hover
    transform: translateX(5px)
    font-weight: 700 !important
    color: #211E1E
.notifications__number
   
    font-size: 10px
    color: #fff
    background: green
    border-radius: 50%
    width: 25px
    height: 25px
    display: flex
    align-items: center
    margin-left: 10px
    justify-content: center