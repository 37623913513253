.sign-up-container
    box-sizing: border-box
    padding: 40px 24px
    display: flex
    flex-direction: row
    @media (max-width: 640px)
        width: 100%
        flex-direction: column
        padding: 40px 0
        align-items: center
.sign-up-title
    font-family: Montserrat, sans-serif
    font-style: normal
    font-weight: bold
    font-size: 36px
    line-height: 36px
    color: #211E1E
    margin-bottom: 32px
    @media (max-width: 640px)
        font-size: 24px
        line-height: 28px
        margin-bottom: 24px
.sign-up-sub-title
    max-width: 440px
    font-family: Montserrat, sans-serif
    font-style: normal
    font-weight: bold
    font-size: 24px
    line-height: 28px
    color: #211E1E
    opacity: 0.54
    margin-bottom: 40px
    @media (max-width: 640px) 
        font-size: 16px
        margin-bottom: 48px
.footer__links
    position: fixed
    bottom: 0
    left: 0
    position: fixed
    bottom: 0
    left: 0
    width: 100%
    display: flex
    justify-content: space-around
    padding: 40px
    background: #fff
    a
        color: rgb(33, 150, 83)
        font-size: 18px
        text-decoration: underline