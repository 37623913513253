.info-consultation-container
    position: relative
    right: 0
    margin-left: auto
    width: 100%
    height: 100%
    display: flex
    flex-direction: column

    box-sizing: border-box
    padding: 48px 40px 120px 40px

    overflow-y: scroll
    @media (max-width: 640px)
        padding: 24px 26px 64px 22px
        width: 100%
        max-width: 100%

    &::-webkit-scrollbar 
        width: 6px
        height: 5px
        margin: 30px
    &::-webkit-scrollbar-track 
        box-shadow: 0
        border-radius: 5px
        margin: 20px
    &::-webkit-scrollbar-thumb 
        background: #219653
        border-radius: 5px
        height: 10%

    &__header
        display: flex
        flex-direction: column
        margin-top: 36px

        &__title
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 24px
            line-height: 28px
            color: #211E1E
            @media (max-width: 640px)
                font-size: 16px

        &__description
            display: flex
            flex-direction: row
            justify-content: flex-start
            margin: 15px 0 20px 24px

            &__text
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: bold
                font-size: 15px
                line-height: 20px
                color: #211E1E
                margin-left: 18px
                max-width: 452px

    &__main-section
        display: flex
        flex-direction: column
        width: 100%
        @media (max-width: 640px)
            padding-bottom: 62px
        &__panel
            display: flex
            flex-direction: column
            width: 100%
            margin-bottom: 9px

            span
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: 500
                font-size: 15px
                line-height: 20px
                color: #211E1E
                margin-bottom: 9px
                max-width: 1067px
            
            div
                width: 100%
                height: 109px
                background: #FFFFFF
                border-radius: 12px
                box-sizing: border-box
                padding: 8px 16px

                textarea
                    border: none
                    width: 100%
                    height: 100%
                    resize: none
                    outline: none

                    font-family: Montserrat, sans-serif
                    font-style: normal
                    font-weight: normal
                    font-size: 16px
                    line-height: 20px

                    color: #211E1E
                    &:active
                        border: none
                        outline: none
                    &:focus
                        border: none
                        outline: none
                    &::placeholder
                        font-family: Montserrat, sans-serif
                        font-style: normal
                        font-weight: normal
                        font-size: 16px
                        line-height: 20px

                        color: rgba(33, 30, 30, 0.5)
