.main-instruction-measurement-container
    position: relative
    margin-left: auto
    right: 0
    width: 100%
    height: calc(100% - 72px)
    max-height: calc(100% - 72px)
    display: flex
    flex-direction: column

    overflow-y: scroll
    overflow-x: hidden
    padding-bottom: 10px
    
    box-sizing: border-box
    padding-bottom: 60px
    &::-webkit-scrollbar 
      width: 6px
      height: 5px

    &::-webkit-scrollbar-track 
      box-shadow: 0
      border-radius: 5px

    &::-webkit-scrollbar-thumb 
      background: #219653
      border-radius: 5px
      height: 10%

    @media (max-width: 640px)
        width: 100%
        max-width: 100%
        display: flex
        flex-direction: column
        margin: 0
        padding-bottom: 60px
        height: 100%

    &__title-wrapper
        display: flex
        flex-direction: row
        align-items: center
        width: 100%

        margin: 3vh 40px 18px 40px // dsafksdajfvoijdfoiugh34iohjrvoirehgvjeroivhjuidf
        padding: 0
        height: fit-content
        box-sizing: border-box
        justify-content: space-between

        @media screen and ( max-width: 640px )
            margin-top: 10px
            max-width: 320px
        
        &__sub-wrapper
            display: flex
            flex-direction: column

        &__title
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 24px
            line-height: 24px
            color: #211E1E

            @media screen and ( max-width: 640px )
                display: none

        &__additional-title
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 16px
            line-height: 20px

            color: #219653

            margin-top: 3vh
            max-width: 475px

            @media screen and ( max-width: 640px )
                margin-top: 0px
                font-size: 14px
                line-height: 17px

    &__main-insctruction
        margin-left: 32px
        // max-height: 576px
        height: fit-content
        display: flex
        flex-direction: row
        align-items: center

        @media (max-width: 640px)
            flex-direction: column
            margin: 0
        div
            height: 100%

    &__agreement-panel
        position: relative
        display: flex
        flex-direction: row
        align-items: center
        margin: 20px 40px
        @media (max-width: 640px) 
            display: flex
            flex-direction: row
            
            align-items: center
            margin: 0 auto
            width: 89vw
            padding: 5px 10px
            background: #fff
            border-radius: 15px
            margin-bottom: 60px !important
        span
            margin-left: 20px
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 14px
            line-height: 17px
            color: #211E1E
            
        svg 
            max-width: 28px
            max-height: 28px
                
.large-instruction-panel
    background: #FFFFFF
    border-radius: 20px
    display: flex
    flex-direction: column
    box-sizing: border-box
    max-width: calc(100% - 90px)
    margin: 0 auto 0 40px
    padding: 38px 36px 34px 44px
    font-family: Montserrat, sans-serif
    @media (max-width: 640px)
        max-width: calc(100% - 10px)
        margin: 0 auto
        padding: 12px 20px 80px 12px

    span
        // font-family: Montserrat, sans-serif
        // font-style: normal
        // font-weight: bold
        // font-size: 18px
        // line-height: 22px
        // color: #211E1E
        // margin-bottom: 8px
    .main-text
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: normal
        font-size: 14px
        line-height: 18px
        /* or 129% */


        color: #000000

    .agreement-panel
        display: flex
        flex-direction: row
        align-items: center
        margin: 20px 40px
        span
            margin-left: 20px
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 14px
            line-height: 17px
            color: #211E1E
            
        svg 
            max-width: 28px
            max-height: 28px

.measurement-end-commentary-wrapper
    display: flex
    flex-direction: column
    
    margin: 12px 36px
    @media (max-width: 640px)
        margin: 12px 6px
    .title
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: 400
        font-size: 18px
        line-height: 24px
        color: #211E1E
        margin-bottom: 4px
    .input
        width: 100%
        background: #FFFFFF
        border: 1px solid #E6E6E8
        border-radius: 20px
        box-sizing: border-box
        padding: 24px 36px
        textarea
            width: 100%
            outline: none
            border: none
            height: 50px
            resize: none
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 400
            font-size: 14px
            line-height: 17px
            color: #211E1E
            &::placeholder
                color: rgba(33, 30, 30, 0.5) 
    .sub-title
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: 700
        font-size: 12px
        line-height: 15px
        color: #219653
        margin-top: 8px