.info-statistic-blocks
    display: flex
    flex-direction: row
    align-items: flex-start
    justify-content: space-around
    @media (min-width: 640px)
        padding: 15px 0
        min-width: 300px
    @media (max-width: 640px)
        flex-direction: column
    &__block
        width: 100%
        box-sizing: border-box
        padding: 20px 20px 0 20px
        display: flex
        flex-direction: column
        background: #FFFFFF
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
        margin: 0px 15px
        border-radius: 5px
        height: 230px
        margin-bottom: 13px
        &:last-of-type
            margin-bottom: 0
        @media (max-width: 640px)
            width: 92% !important
            &:last-of-type
                margin-bottom: 13px
        &__title
            margin-bottom: 10px
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 700
            font-size: 14px
            line-height: 17px
            display: flex
            justify-content: space-between
            color: #000000
        &__row
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 700
            font-size: 12px
            line-height: 15px
            color: #000000
            margin-bottom: 7px
            span:last-of-type
                font-family: 'JetBrains Mono'
.circleQuest
    display: flex
    align-items: center
    position: relative
    svg
        width: 24px
        height: 24px
        position: relative
        z-index: 2
    .showMe
        position: absolute
        right: 25px
        top: 20px
        background: #fff
        border-radius: 10px
        padding: 10px
        width: 420px
        z-index: 22222222220
        box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2)
        -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2)
        -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2)
       
        display: none
        @media (max-width: 640px)
            width: 73vw
        
        h3
            font-size: 16px
        p
            font-size: 12px
.circleQuest:hover
    .showMe
        display: block
.flexic
    width: 250px
