.mobile__modal
    background: #fff
    width: 100vw
    height: 100vh
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    position: fixed
    top: 0
    left: 0
    z-index: 99999
    p
        width: 90%
        text-align: center
        font-size: 4vw
    h3
        font-size: 8vw
        margin-bottom: 0
    img
        width: 30%
        margin-top: -60px