.instruction-measurement-container
    position: relative
    margin-left: auto
    right: 0
    width: 100%
    height: calc(100% - 72px)
    max-height: calc(100% - 72px)
    display: flex
    flex-direction: column

    overflow-y: scroll
    overflow-x: hidden
    padding-bottom: 60px
    box-sizing: border-box
    &::-webkit-scrollbar 
      width: 6px
      height: 5px

    &::-webkit-scrollbar-track 
      box-shadow: 0
      border-radius: 5px

    &::-webkit-scrollbar-thumb 
      background: #219653
      border-radius: 5px
      height: 10%

    //padding-bottom: 72px
    @media (max-width: 640px)
        width: 100%
        max-width: 100%
        padding-bottom: 40px
    &__title-wrapper
        display: flex
        flex-direction: row
        align-items: center
        width: 100%

        margin: 3vh 40px 18px 40px // dsafksdajfvoijdfoiugh34iohjrvoirehgvjeroivhjuidf
        padding: 0
        height: fit-content
        box-sizing: border-box
        justify-content: space-between

        @media screen and ( max-width: 640px )
            margin-top: 10px
            max-width: 320px
            margin: 3vh 40px 18px 16px
        &__sub-wrapper
            display: flex
            flex-direction: column

        &__title
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 24px
            line-height: 24px
            color: #211E1E

            @media screen and ( max-width: 640px )
                display: none

        &__additional-title

            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 16px
            line-height: 20px

            color: #219653

            margin-top: 3vh
            max-width: 475px

            @media screen and ( max-width: 640px )
                margin-top: 0px
                font-size: 14px
                line-height: 17px

    &__main-insctruction
        margin-left: 32px
        max-height: 576px
        height: 100%
        display: flex
        flex-direction: row
        align-items: center

        @media (max-width: 640px)
            flex-direction: column
            margin-bottom: 72px
        div
            height: 100%

    &__agreement-panel
        display: flex
        flex-direction: row
        align-items: center
        margin: 20px 40px
        box-sizing: border-box
        padding: 16px 108px 16px 28px
        background: #F4F4F4
        border-radius: 20px
        width: fit-content
        @media ( max-width: 640px )
            margin-left: 0
            width: 100%
            padding: 10px 16px
            margin: 0
           
            margin-bottom: 60px !important

        span
            margin-left: 20px
            margin-top: 6px
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 14px
            line-height: 17px
            color: #211E1E
            
        svg 
            max-width: 28px
            max-height: 28px