.choose-measurement-panel
    width: 100%
    background: white

    display: flex
    flex-direction: row
    align-items: center

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07)
    border-radius: 12px

    box-sizing: border-box
    padding: 12px 24px

    margin: 7px 0

    @media screen and ( max-width: 640px )
        height: fit-content
        flex-direction: column
        padding: 12px
    
    &__small-row
        display: flex
        flex-direction: row
        align-items: center
        width: fit-content
        width: 100%

    &__check-mark
        display: flex
        justify-content: center
        align-items: center
        transform: scale(0.75)
        margin-right: 4px
        cursor: pointer

    &__person-icon
        transform: scale(0.75)
        margin-right: 12px

    &__title
        display: block
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: 700
        font-size: 18px
        line-height: 28px
        // height: 56px
        color: #211E1E
        max-width: 50%
        margin-right: 8px
        cursor: pointer
        @media screen and ( max-width: 640px )
            max-width: 100%
    a
        text-decoration: none
        color: #211E1E
    &__icons   
        display: flex
        flex-direction: row
        align-items: center
        margin-left: 24px
        width: 124px
        @media screen and ( max-width: 640px )
            margin-left: auto
            width: auto
        div
            transform: scale(0.8)
            margin-right: 24px
            width: 38px
            height: 38px
            display: flex
            justify-content: center
            align-items: center

        & > svg
            transform: scale(0.8)
            cursor: pointer

    &__additional-data
        display: flex
        flex-direction: row
        align-items: center
        margin-left: auto
        @media screen and ( max-width: 640px )
            flex-direction: column
            margin-left: -20px
        &__column       
            position: relative
            display: flex
            flex-direction: column
            align-items: center
            margin-right: 32px
            max-width: 440px
            span
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: 500
                font-size: 14px
                line-height: 28px
                color: #211E1E
                opacity: 0.54
            div
                margin-top: -8px
                max-width: 24vw
                display: block
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: 700
                font-size: 18px
                line-height: 28px
                color: #211E1E
                @media screen and ( max-width: 640px )
                    margin: 0 0 0 12px
                    max-width: 52vw
            @media screen and ( max-width: 640px )
                flex-direction: row
                width: 100%
                max-width: 100%
                margin: 0
                margin-right: auto
            
.type_measurement_width
    width:380px
.showPar
    div
        white-space: normal