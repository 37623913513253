.sign-up-form-wrapper
    max-width: 332px
    display: flex
    flex-direction: column
    position: relative
    div
        position: relative

    .text-input
        margin: 8px 0
    
    .green-button
        margin-top: 24px
        width: 332px
.registration-politics
    display: flex
    align-items: flex-start
    margin-top: 15px
    input
        width: 18px
        height: 18px
        margin-right: 10px