.consultations-set-value-modal-wrapper
    position: fixed
    left: 0
    top: 0
    z-index: 999
    width: 100vw
    height: 100vh
    background: rgba(1,1,1,0.3)

    display: flex
    justify-content: center
    align-items: center

    
    .consultations-set-value-modal
        display: flex
        flex-direction: column

        background: #FFFFFF

        width: 100%
        height: 100%
        max-width: 556px
        max-height: 320px

        border-radius: 12px
        box-sizing: border-box
        padding: 24px 32px

        @media (max-width: 640px)
            max-width: calc(100vw - 40px)
            max-height: 414px
            padding: 14px 20px 37px 20px
        &__title
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 700
            font-size: 28px
            line-height: 30px
            color: #211E1E
        
        &__description
            margin-top: 16px
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 500
            font-size: 15px
            line-height: 20px
            color: #211E1E
            opacity: 0.54

        &__buttons
            display: flex
            flex-direction: row
            margin-top: 24px
            @media (max-width: 640px)
                justify-content: space-between
            .green-button
                max-width: fit-content
                margin-right: 16px

        &__input-wrapper
            display: flex
            flex-direction: row
            align-items: center
            margin-top: 32px
            @media (max-width: 640px)
                flex-direction: column

            &__commission
                margin-left: 16px
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: 500
                font-size: 17px
                line-height: 20px
                color: #211E1E
                span
                    margin: 0 4px
                @media (max-width: 640px)
                    margin-top: 12px
                    margin-right: auto
            .text-input
                background: #FFFFFF
                border: 2px solid #E6E6E8
                box-sizing: border-box
                border-radius: 12px
                @media (max-width: 640px)
                    width: 100%