.measurement-type-card-wrapper
    position: relative
    display: flex
    flex-direction: column
    margin: 0 15px
    width: fit-content
    min-width: 30vw
    max-width: 70vw
    max-height: 600px
    height: 100%
    transition: 0.3s
    overflow: hidden
    box-sizing: border-box
    background: #FFFFFF
    border-radius: 20px
    padding: 40px 15px 18px 15px
    border: 4px solid #fff
    cursor: pointer
    @media (max-width: 640px)
        //width: 120vw
        min-width: 94vw
        height: 100%
        max-height: 440px
        margin: 0 9px
        padding: 10.25px 11px 3px 11px
    &__title
        margin-left: 25px
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: bold
        font-size: 16px
        line-height: 19px
        color: #211E1E

        max-width: 290px

        @media (max-width: 640px)
            max-width: 200px
    &__desc
        width: 100%
        margin-top: 5px
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: 500
        font-size: 12px
        line-height: 15px
        color: #211E1E
        div
            @media (max-width: 640px)
                height: 50vh
                overflow-y: scroll
                p:last-child 
                    padding-bottom: 50px

    &__image-wrapper
        position: relative
        bottom: 10%
        margin: auto auto 0 auto
        height: 15.6%
        @media (min-height: 944px)
            bottom: 3%
        @media (max-width: 640px)
            display: none
    &__icon
        position: absolute
        top: 42px
        right: 42px
        @media (max-width: 640px)
            top: 22px

.active-measurement-type-card-wrapper
    border: 4px solid #219653
    transform: translateY(-10px)
    @media (max-width: 640px)
         transform: none