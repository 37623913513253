.chat-control-panel-wrapper
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    padding: 20px 24px
    box-sizing: border-box
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1)
    width: 100%
    background: white
    position: absolute
    bottom: 0
    right: 0

    @media (max-width: 640px)
        flex-direction: column
        padding: 0
        bottom: 54px
        
    &__input-wrapper
        & > div
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            & > div
                @media ( max-width: 640px )
                    min-width: calc(100% - 48px)
                    max-width: 100%
                    width: calc(100% - 48px)
        input
            background: #FFFFFF
            border: 2px solid #E6E6E8
            box-sizing: border-box
            padding: 10px 16px
            border-radius: 12px
            max-width: 508px
            min-width: 508px
            width: 100%
            @media ( max-width: 640px )
                min-width: 100%
                max-width: 100%
                width: 100%
                
        button
            width: 40px
            height: 40px

            display: flex
            justify-content: center
            align-items: center
            background: #FFFFFF
            border: 2px solid #219653
            box-sizing: border-box
            border-radius: 12px

            margin-left: 8px

            transition: all .4s ease
            cursor: pointer
            svg
                path 
                    transition: all .4s ease
            &:hover
                background: #219653
                svg
                    path
                        stroke: #FFFFFF

        

        @media (max-width: 640px)
            width: 100%
            padding: 20px 24px
            box-sizing: border-box
            border-bottom: 2px solid #E6E6E8

    &__buttons-wrapper
        display: flex
        flex-direction: row
        align-items: center
        .green-button
            margin-left: 20px
            width: fit-content        
        @media (max-width: 640px)
            width: 100%
            justify-content: space-between
            box-sizing: border-box
            padding: 10px
            .green-button
                margin-left: auto
                width: fit-content
            
        &__consultant-waiting-pay
            display: flex
            flex-direction: row
            align-items: center
            @media (max-width: 640px)
                margin-left: auto
            &__button
                position: relative
                box-sizing: border-box
                padding: 10px 16px

                background: #E3655D
                border-radius: 12px

                font-family: Open Sans, sans-serif
                font-style: normal
                font-weight: 600
                font-size: 16px
                color: #FFFFFF

                cursor: pointer
                transition: all .4s ease
                &:hover
                    background: #da584f
                @media (max-width: 640px)
                    margin-left: auto
            span
                font-family: Open Sans, sans-serif
                font-style: normal
                font-weight: 400
                font-size: 14px
                line-height: 20px
                color: #211E1E
                margin: 0 24px 0 8px
            
            @media (max-width: 640px)
                span
                    display: none
                svg
                    display: none
.confirm__close__consultation
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background: rgba(0,0 ,0 ,0.7 )
    display: flex
    align-items: center
    justify-content: center
    z-index: 99997
    .confirm__close__consultation__body
        padding: 20px
        width: 260px
        background: #fff
        border-radius: 15px
        text-align: center
        div
            display: flex
            justify-content: space-between
            div
                width: fit-content
        p
            font-size: 18px