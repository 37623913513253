.progress-rating-panel-wrapper
    width: 100%
    height: 8px
    position: absolute
    top: 72px
    right: 0
    background: rgba(196, 196, 196, 0.5)
    z-index: 998
    @media (max-width: 640px)
        top: 56px
        left: 0
        width: 100vw
        min-width: 100vw
    div
        height: 8px
        background: #219653
        position: absolute
        left: 0
        min-width: 32px
        z-index: 995
        max-width: calc(100% - 32px)
        .percantage-wrapper
            margin-top: -12px
            position: absolute
            right: 0
            background: #219653
            width: 32px
            height: 32px
            border-radius: 50%
            display: flex
            justify-content: center
            align-items: center
            z-index: 996
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: bold
            font-size: 9px
            line-height: 24px

            letter-spacing: 1px
            text-transform: uppercase

            color: #FFFFFF
