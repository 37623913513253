.office_em
    padding: 20px
    border-radius: 10px
    background: #fff
    -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2)
    -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2)
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2)
    margin-bottom: 10px
    font-size: 20px
    width: 400px
    font-weight: bold
    @media (max-width: 640px)
        width: 78vw
.bottom_office_add
    padding: 15px
    background: #fff
    display: flex
    justify-content: flex-end
    position: absolute
    bottom: 0
    width: 100%
    right: 0
    .green-button
        width: fit-content
    @media (max-width: 640px)
        position: fixed
.pop_add_office
    position: fixed
    width: 100vw
    height:100vh
    background: rgba(0,0,0,0.3)
    top: 0
    left: 0
    display: flex
    align-items: center
    justify-content: center
    z-index: 99999
.pop_office_body
    background:#fff
    padding: 30px
    border-radius: 10px
    display: flex
    flex-direction: column
    width: 270px
    align-items: center
    input
        border-radius: 10px
        outline: none
        font-size: 18px
        border: 1px solid black
        padding: 10px
        margin-bottom: 25px
        width: 100%
        box-sizing: border-box
    p
        font-size: 20px
        font-weight: bold
        margin: 0
.buttons_office
    display: flex
    width: 100%
    justify-content: space-between
    div
        width: fit-content
        
.error_office
    color: red
    font-size: 12px
    margin-top: 20px
    margin-bottom: 5px