.group-statistic-wrapper
    display: flex
    flex-direction: column
    width: fit-content
    position: relative
    margin-top: 10px
    max-width: 98%
    //overflow: auto
    @media (max-width: 640px)
        max-width: 100%
        margin-top: 0
        align-items: center
    &__header
        position: relative
        display: flex
        flex-direction: row
        align-items: flex-end
        justify-content: space-between
        height: 128px
        width: 100%
        padding-bottom: 0px
        @media (max-width: 640px)
            overflow: hidden
            width: 1280px 
            max-width: 320px
            height: 140px
            padding-bottom: 8px
        &::-webkit-scrollbar
            display: none
        &__gradient-line
            position: relative
            width: 100%
            display: flex
            flex-direction: row
            justify-content: space-between
            margin-bottom: 28px
            @media (max-width: 640px)
                min-width: 640px
                width: 100%
                position: relative
                margin-left: -100px
            & > div
                position: relative
                height: 5px
                @media (max-width: 640px)
                    min-width: 50px
                
        &__panels
            width: 100%
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-around
            position: absolute
            transition: 0.5s
            margin-bottom: 0px
            @media (max-width: 640px)
                width: 721px !important
            & > div
                position: relative
                z-index: 12
                width: 60px
                max-width: 60px
                background: #F4F4F4 
                height: 60px
                display: flex
                justify-content: center
                align-items: center

                font-family: JetBrains Mono, monospace
                font-style: normal
                font-weight: 400
                font-size: 16px
                line-height: 21px
                display: flex
                align-items: center
                text-align: center

                color: #000000
                @media (max-width: 640px)
                    background: #FFF
                    margin-left: 50px
                &:hover
                    .count
                        font-size: 21px
                svg
                    position: absolute
                    cursor: pointer

                .count
                    display: flex
                    justify-content: center
                    align-items: center
                    position: absolute
                    font-family: JetBrains Mono, monospace
                    font-style: normal
                    font-weight: 400
                    font-size: 16px
                    line-height: 21px
                    display: flex
                    align-items: center
                    text-align: center

                    color: #000000
                    cursor: pointer
                    transition: all .4s ease


                .title
                    margin-bottom: 140px
                    font-family: Montserrat, sans-serif
                    font-style: normal
                    font-weight: 400
                    font-size: 13px
                    line-height: 20px
                    display: flex
                    align-items: center
                    justify-content: center
                    text-align: center
                    color: #000000
                    min-width: 103px

    &__cursor
        margin-top: 4px
        transition: all .3s ease
        svg
            transition: all .4s ease

        
    &__main
        position: relative
        width: 98%
        max-width: 96%
        height: 670px
        margin: 2px 20px 0 20px

        background: rgba(70, 48, 48, 0.05)
        border: 5px solid #E3655D
        border-radius: 12px

        margin-top: 2px    
        transition: all .4s ease   

        display: flex
        flex-direction: column
        align-items: center
        @media (max-width: 640px)
            height: 600px
            width: 100%
            max-width: calc(100% - 16px)
            margin: 10px 4px 8px 4px
        &__title    
            font-family: Montserrat, sans-serif
            font-style: normal
            font-weight: 700
            font-size: 24px
            line-height: 50px
            color: #000000

            @media (max-width: 640px)
                font-size: 16px
                line-height: 20px
                max-width: calc(100% - 48px)
                margin: 12px 24px
                box-sizing: border-box
                height: 78px
                text-align: center
        &__list-header
            max-width: 49%
            margin-left: auto
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            margin-bottom: 4px
            box-sizing: border-box
            padding-right: 12px
            position: relative
            @media (max-width: 640px)
                top: 0
            span
                font-family: JetBrains Mono, monospace
                font-style: normal
                font-weight: 400
                font-size: 16px
                line-height: 21px
                display: flex
                align-items: center
                color: #000000
            div
                position: absolute
                left: -15%
                font-family: Montserrat, sans-serif
                font-style: normal
                font-weight: 400
                font-size: 16px
                line-height: 20px
                color: #000000
                @media (max-width: 640px)
                    left: -30%
        &__left-button
            top: calc(50% - 25px)
            left: 4px
            position: absolute
            cursor: pointer
            z-index: 12
            max-width: 50px
            max-height: 50px
            @media (max-width: 640px)
                top: 52px
            svg
                circle
                    transition: all .4s ease
                path
                    transition: all .4s ease
            &:hover
                svg
                    circle
                        fill-opacity: 1
                    path
                        fill-opacity: 1
        &__right-button
            position: absolute
            right: 4px
            top: calc(50% - 25px)
            transform: rotate(180deg)
            cursor: pointer
            z-index: 12
            max-width: 50px
            max-height: 50px
            @media (max-width: 640px)
                top: 52px
            svg
                circle
                    transition: all .4s ease
                path
                    transition: all .4s ease
            &:hover
                svg
                    circle
                        fill-opacity: 1
                    path
                        fill-opacity: 1
            
        &__list
            margin: 24px 8% 8px 8%
            max-width: 84%
            overflow-y: scroll
            @media (max-width: 640px)
                width: 100%
                max-width: calc(100%)
                margin: 150px 4px 8px 4px
            &::-webkit-scrollbar 
                width: 6px
                height: 5px
                margin: 30px
            &::-webkit-scrollbar-track 
                box-shadow: 0
                border-radius: 5px
                margin: 20px
            &::-webkit-scrollbar-thumb 
                background: #219653
                border-radius: 5px
                height: 10%

            .panel
                width: 100%
                height: 66px
                box-sizing: border-box
                padding: 7px 11px
                display: flex
                flex-direction: row
                align-items: center
                justify-content: space-between
                background: #FFFFFF
                border-radius: 40px
                margin-bottom: 10px
                @media (max-width: 640px)
                    border-radius: 30px
                    width: 98%
                    margin: 0 auto
                    margin-bottom: 10px
                    margin-left: 5px
                & > div
                    max-width: 49%
                &__name-wrapper
                    display: flex
                    flex-direction: row
                    align-items: center
                    max-width: 50%
                    position: relative
                    &__image
                        width: 52px
                        height: 52px
                        min-width: 52px
                        min-height: 52px
                        max-width: 52px
                        max-height: 52px
                        border-radius: 50%
                        display: flex
                        justify-content: center
                        align-items: center
                        font-family: Montserrat, sans-serif
                        font-style: normal
                        font-weight: 700
                        font-size: 24px
                        line-height: 29px
                        display: flex
                        align-items: center

                        color: #FFFFFF
                        @media (max-width: 640px)
                            width: 26px
                            height: 26px
                            min-width: 26px
                            min-height: 26px
                            max-width: 32px
                            max-height: 32px
                    &__name
                        margin-left: 32px
                        width: 50%
                        font-family: Montserrat, sans-serif
                        font-style: normal
                        font-weight: 400
                        font-size: 14px
                        line-height: 22px
                        color: #000000
                        @media (max-width: 640px)
                            margin-left: 4px
                            font-size: 12px
                            line-height: 17px
                            max-width: 40%

                    &__number
                        margin-right: 8%
                        margin-left: auto
                        font-family: JetBrains Mono, monospace
                        font-style: normal
                        font-weight: 400
                        font-size: 24px
                        line-height: 32px
                        color: black
                        @media (max-width: 640px)
                            margin-right: 21%
                            font-size: 17px
                    &__values
                        margin-left: auto
                        position: absolute
                        width: fit-content
                        right: -12px
                        display: flex
                        flex-direction: column
                        justify-content: space-between
                        width: 24px
                        @media (max-width: 640px)
                            right: -2px
                        span
                            font-family: Montserrat, sans-serif
                            font-style: normal
                            font-weight: 400
                            font-size: 13px
                            line-height: 16px
                            color: #000000
                            
                &__value-wrapper
                    display: flex
                    flex-direction: column
                    justify-content: space-between
                    max-width: 54%
                    height: 100%
                    @media (max-width: 640px)
                        //display: none
                    & > div
                        display: flex
                        flex-direction: column
                        align-items: center
                        justify-content: space-between
                        width: 100%
                    &__line
                        width: 100%
                        height: 22px
                        background: rgba(0, 0, 0, 0.05)
                        border-radius: 30px
                        margin: auto
                        div
                            height: 100%
                            display: flex
                            justify-content: center
                            align-items: center
                            font-family: JetBrains Mono, monospace
                            font-style: normal
                            font-weight: 700
                            font-size: 14px
                            line-height: 18px
                            display: flex
                            align-items: center
                            border-radius: 30px
.text_akignnn
    p
        text-align: center
        @media (max-width: 640px)
            font-size: 14px

.groups__title
    font-family: 'Montserrat'
    font-style: normal
    font-weight: 600
    font-size: 18px
    margin-bottom: 5px
    margin-top: 25px
.groups__text
    font-family: 'Montserrat'
    font-style: normal
    font-weight: 400
    font-size: 16px
    margin-bottom: 25px
    p 
        margin: 0