.article-section-wrapper
    margin-left: 20px
    width: 100%

    position: relative
    padding-right: 30px
    height: 90%
    max-height: 90%
    box-sizing: border-box
    @media screen and ( max-width: 640px )
        margin: 0
        height: fit-content
        padding-bottom: 40px
        max-height: unset
        padding-right: 0

    &__title
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: bold
        font-size: 36px
        line-height: 40px
        color: #211E1E
        @media (max-width: 640px)
            font-size: 24px
            line-height: 24px

    &__main-text
        font-family: Montserrat, sans-serif
        font-style: normal
        font-weight: normal
        font-size: 14px
        line-height: 20px
        max-height: 662px
        padding-right: 10px
        overflow-y: scroll
        color: #000000
        margin-top: 32px
        
        @media screen and ( max-width: 640px )
            margin-top: 24px
            width: 100%
            max-height: unset
            line-height: 24px
            padding-bottom: 50px
        
    &__control-panel
        display: flex
        margin-top: 15px
        position: absolute
        bottom: 0
        flex-direction: row
        justify-content: flex-end
        width: 100%
        background: #F4F4F4
        @media ( max-width: 640px )
                position: fixed
                margin-top: 20px
                width: 100%
                padding: 10px
                text-align: center
                justify-content: flex-start
                
        & > .right
            margin-left: 24px
            @media ( max-width: 640px )
                margin-left: 4px
            span
                margin-right: 4px
                @media ( max-width: 640px )
                    margin-right: 0
            svg 
                transform: rotate(180deg)
        .white-button
            padding: 10px 16px
            box-sizing: border-box
            
            @media screen and ( max-width: 640px )
                padding: 10px 12px

