.person-list-search
    width: 100%
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    margin-bottom: 16px
    @media (max-width: 640px)
        flex-direction: column-reverse
    &__left-side
        display: flex
        flex-direction: row
        align-items: center
        
        @media (max-width: 640px)
            width: 100%
            flex-direction: column
        &__search-input
            display: flex
            flex-direction: row
            align-items: center
            background: #FFFFFF
            border: 2px solid #E6E6E8
            box-sizing: border-box
            padding: 8px
            border-radius: 12px
            width: 305px
            @media (max-width: 640px)
                width: 100%
            input
                outline: none
                border: none
                margin-left: 8px
                font-family: 'Montserrat', sans-serif
                font-style: normal
                font-weight: 400
                font-size: 16px
                line-height: 20px
                color: #211E1E
                &::placeholder
                    font-family: 'Montserrat', sans-serif
                    font-style: normal
                    font-weight: 400
                    font-size: 16px
                    line-height: 20px
                    color: #211E1E
                    opacity: 0.54
        
        &__buttons
            display: flex
            flex-direction: row
            align-items: center
            @media (max-width: 640px)
                flex-direction: column
                margin-right: auto
                margin-left: -12px
            & > div
                @media (max-width: 640px)
                    margin-right: auto
                    margin-top: 16px
        .white-button
            margin-left: 16px
    
    &__right-side
        display: flex
        flex-direction: row
        align-items: center
        span
            margin-right: 16px
            font-family: 'Montserrat', sans-serif
            font-style: normal
            font-weight: 500
            font-size: 14px
            line-height: 28px
            color: #211E1E
            opacity: 0.54
        &__toggle-input
            background: #FFFFFF
            border: 1px solid #ECEDF0
            box-sizing: border-box
            border-radius: 4px
            padding: 8px 16px
            width: 165px
.person-list-search__right-side__toggle-input
            position: relative
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between

            box-sizing: border-box
            padding: 10px 20px
            background: #FFFFFF
            border: 1px solid #ECEDF0
            box-sizing: border-box
            border-radius: 4px
            margin-right: 8px
            min-width: 100%
            &__icon
                cursor: pointer
                width: 20%
                height: 100%
                display: flex
                justify-content: center
                align-items: center
                svg
                    transition: transform .8s ease
                    margin-left: auto
.person-list-search
    .search-filter-consultant-wrapper__list-wrapper__top-side
        min-width: unset
        width: 350px
        @media (max-width: 640px)
            width: 100%
    .search-filter-consultant-wrapper__list-wrapper
        display: flex
        align-items: center
        max-width: 500px
        justify-content: space-between
        @media (max-width: 640px)
            margin: 16px 0
            flex-direction: column
            align-items: flex-start
    .search-filter-consultant-wrapper__list-wrapper__toggle-list
        top: 3px
        left: 142px
        max-height: unset
        border: 1px solid #ECEDF0
        height: auto
        width: 350px
        padding-top: 5px
        padding-bottom: 5px
        max-width: unset
        @media (max-width: 640px)
            left: 0
            top: 26px
            width: 100%
.Sort__panel__span
    color: #211E1E
    opacity: 0.54
    margin-left: 8px